
$rotatePercent: 7.7;

.root-class-design20 {

  @keyframes pulse {
    0% {
      filter: blur(3px);
    }
  
    50% {
      filter: blur(1vh);
    }
  
    100% {
      filter: blur(3px);
    }
  }

  .pulse-blur {
    animation: pulse 1.5s infinite;
  }

  .table-cards {
    //  left: 40%;
    top: 25%;
    //left: unset;
    width: 20%;
    min-width: 150px;
    max-width: 200px;
    //margin-left: 45%;
    //  margin-right: 37%;
    //  z-index: 988;

    height: 30%;
    width: 20% !important;
    //margin-left: 40%!important;
    top: unset !important;
    bottom: 36% !important;
    transform: translate(-50%, 0);
    left: 50%;
    position: absolute;

    @media (max-width: 1200px) {
      top: 26%;
      //margin-left: 44%;
    }

    @media (max-width: 992px) {
      top: 28%;
      //margin-left: 42%;
    }

    @media (max-width: 768px) {
      top: 32%;
      //margin-left: 40%;
    }

    @media (max-height: 800px) {
      top: 25%;
      //margin-left: 45%;
    }

    @media (max-height: 700px) {
      top: 23%;
      //margin-left: 45%;
    }

    @media (max-height: 600px) {
      top: 21%;
      //margin-left: 45%;
    }

    @media (max-width: 582px) {
      //margin-left: 40%;
    }
    //  border: 1px solid red;

    .card-order-0 {
      z-index: 10;
    }

    .card-order-1 {
      z-index: 20;
    }

    .card-order-2 {
      z-index: 30;
    }

    .table-card {
      //  width: 100px;
      //  height: 149px;

      width: $cardWidth;
      height: $cardHeight;
      min-width: 60px;
      min-height: 89px;
      width: 95px;
      height: 143px;
      background-color: transparent;
      float: left;
      margin-right: -58px;
      border: none;
      transition: none !important;
      cursor: default;
      position: absolute;
      //  background-size: $cardWidth $cardHeight;
      background-size: 100% 100%;
      background-position: top center;
      background-repeat: no-repeat;

      &-♠︎-9 {
        background-image: url('../../../../../images/cards-white-fill/♠︎-9.png');
      }

      &-♠︎-10 {
        background-image: url('../../../../../images/cards-white-fill/♠︎-10.png');
      }

      &-♠︎-A {
        background-image: url('../../../../../images/cards-white-fill/♠︎-A.png');
      }

      &-♠︎-J {
        background-image: url('../../../../../images/cards-white-fill/♠︎-J.png');
      }

      &-♠︎-Q {
        background-image: url('../../../../../images/cards-white-fill/♠︎-Q.png');
      }

      &-♠︎-K {
        background-image: url('../../../../../images/cards-white-fill/♠︎-K.png');
      }

      &-♣︎-9 {
        background-image: url('../../../../../images/cards-white-fill/♣︎-9.png');
      }

      &-♣︎-10 {
        background-image: url('../../../../../images/cards-white-fill/♣︎-10.png');
      }

      &-♣︎-A {
        background-image: url('../../../../../images/cards-white-fill/♣︎-A.png');
      }

      &-♣︎-J {
        background-image: url('../../../../../images/cards-white-fill/♣︎-J.png');
      }

      &-♣︎-Q {
        background-image: url('../../../../../images/cards-white-fill/♣︎-Q.png');
      }

      &-♣︎-K {
        background-image: url('../../../../../images/cards-white-fill/♣︎-K.png');
      }

      &-♥-9 {
        background-image: url('../../../../../images/cards-white-fill/♥-9.png');
      }

      &-♥-10 {
        background-image: url('../../../../../images/cards-white-fill/♥-10.png');
      }

      &-♥-A {
        background-image: url('../../../../../images/cards-white-fill/♥-A.png');
      }

      &-♥-J {
        background-image: url('../../../../../images/cards-white-fill/♥-J.png');
      }

      &-♥-Q {
        background-image: url('../../../../../images/cards-white-fill/♥-Q.png');
      }

      &-♥-K {
        background-image: url('../../../../../images/cards-white-fill/♥-K.png');
      }

      &-♦︎-7 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-7.png');
      }

      &-♦︎-8 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-8.png');
      }

      &-♦︎-9 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-9.png');
      }

      &-♦︎-10 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-10.png');
      }

      &-♦︎-A {
        background-image: url('../../../../../images/cards-white-fill/♦︎-A.png');
      }

      &-♦︎-J {
        background-image: url('../../../../../images/cards-white-fill/♦︎-J.png');
      }

      &-♦︎-Q {
        background-image: url('../../../../../images/cards-white-fill/♦︎-Q.png');
      }

      &-♦︎-K {
        background-image: url('../../../../../images/cards-white-fill/♦︎-K.png');
      }
      // left card
      &.card-0 {
        //  top: 20px;
        //  left: -75px;
        top: 2vmin;
        left: -7.5vmin;
        transform: rotate(calc(-25deg));
      }
      // bottom card
      &.card-1 {
        //  top: 60px;
        //  left: 40px;
        top: 6vmin;
        left: 4vmin;
      }
      // right card
      &.card-2 {
        //  top: 20px;
        //  left: 150px;
        top: 2vmin;
        left: 15vmin;
        transform: rotate(calc(25deg));
      }
      // top card
      &.card-3 {
        //  top: -10px;
        //  left: 40px;
        top: -1vmin;
        left: 4vmin;
        position: absolute;
      }

      &.student-card-0 {
        top: 13vmin;
        left: 6.5vmin;
        transform: rotate(calc(0deg));
      }

      &.student-card-1 {
        top: 2vmin;
        left: 9vmin;
        transform: rotate(calc(0deg));
      }

      @media (max-width: 700px) {
        &.card-0 {
          left: -80px;
        }
      }

      @media (max-height: 800px) {
        width: 75px;
        height: 112px;
        background-size: 75px 112px;

        &.card-0 {
          top: 10px;
          left: -28px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 105px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 700px) {
        // left card
        &.card-0 {
          top: 10px;
          left: -18px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 95px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 600px) {
        width: 65px;
        height: 96px;
        background-size: 65px 96px;
        // left card
        &.card-0 {
          top: 10px;
          left: -13px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 90px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 450px) {
        width: 60px;
        height: 89px;
        background-size: 60px 89px;
      }
    }

    .middle-card {
      @keyframes move-up-middle {
        0% { transform: translateY(0px); }
        55% { transform: translateY(-11px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-down-middle {
        0% { transform: translateY(0px); }
        55% { transform: translateY(11px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-left-middle {
        0% { transform: translateX(0px); }
        45% { transform: translateX(-10px); }
        100% { transform: translateX(0px); }
      }
      @keyframes move-right-middle {
        0% { transform: translateX(0px); }
        45% { transform: translateX(10px); }
        100% { transform: translateX(0px); }
      }
      @keyframes move-up-2-middle {
        0% { transform: translateY(0px); }
        40% { transform: translateY(-8px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-down-2-middle {
        0% { transform: translateY(0px); }
        40% { transform: translateY(8px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-left-2-middle {
        0% { transform: translateX(0px); }
        60% { transform: translateX(-10px); }
        100% { transform: translateX(0px); }
      }
      @keyframes move-right-2-middle {
        0% { transform: translateX(0px); }
        60% { transform: translateX(10px); }
        100% { transform: translateX(0px); }
      }

      &-drunk-move-up {
        animation: move-up-middle 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down {
        animation: move-down-middle 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left {
        animation: move-left-middle 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right {
        animation: move-right-middle 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-up-2 {
        animation: move-up-2-middle 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down-2 {
        animation: move-down-2-middle 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left-2 {
        animation: move-left-2-middle 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right-2 {
        animation: move-right-2-middle 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
    }
    .left-card {
      @keyframes move-up-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        55% { transform: rotate(calc(-25deg)) translateY(-11px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-down-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        55% { transform: rotate(calc(-25deg)) translateY(11px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-left-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        45% { transform: rotate(calc(-25deg)) translateX(-10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }
      @keyframes move-right-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        45% { transform: rotate(calc(-25deg)) translateX(10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }
      @keyframes move-up-2-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        40% { transform: rotate(calc(-25deg)) translateY(-8px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-down-2-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        40% { transform: rotate(calc(-25deg)) translateY(8px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-left-2-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        60% { transform: rotate(calc(-25deg)) translateX(-10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }
      @keyframes move-right-2-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        60% { transform: rotate(calc(-25deg)) translateX(10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }

      &-drunk-move-up {
        animation: move-up-left 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down {
        animation: move-down-left 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left {
        animation: move-left-left 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right {
        animation: move-right-left 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-up-2 {
        animation: move-up-2-left 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down-2 {
        animation: move-down-2-left 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left-2 {
        animation: move-left-2-left 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right-2 {
        animation: move-right-2-left 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
    }
    .right-card {
      @keyframes move-up-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        55% { transform: rotate(calc(25deg)) translateY(-11px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-down-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        55% { transform: rotate(calc(25deg)) translateY(11px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-left-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        45% { transform: rotate(calc(25deg)) translateX(-10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }
      @keyframes move-right-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        45% { transform: rotate(calc(25deg)) translateX(10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }
      @keyframes move-up-2-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        40% { transform: rotate(calc(25deg)) translateY(-8px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-down-2-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        40% { transform: rotate(calc(25deg)) translateY(8px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-left-2-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        60% { transform: rotate(calc(25deg)) translateX(-10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }
      @keyframes move-right-2-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        60% { transform: rotate(calc(25deg)) translateX(10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }

      &-drunk-move-up {
        animation: move-up-right 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down {
        animation: move-down-right 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left {
        animation: move-left-right 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right {
        animation: move-right-right 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-up-2 {
        animation: move-up-2-right 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down-2 {
        animation: move-down-2-right 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left-2 {
        animation: move-left-2-right 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right-2 {
        animation: move-right-2-right 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
    }

    .table-card-bg {
      //  width: 100px;
      //  height: 149px;

      width: $cardWidth;
      height: $cardHeight;
      min-width: 60px;
      min-height: 89px;
      width: 95px;
      height: 143px;
      background-color: transparent;
      float: left;
      margin-right: -58px;
      border: none;
      transition: none !important;
      cursor: default;
      position: absolute;
      z-index: 5;
      background-size: 100% 100%;
      background-position: top center;
      background-repeat: no-repeat;
      background-image: url('../../../../../images/redesign/game/Cards set.svg');
      // left card
      &.card-0 {
        //  top: 20px;
        //  left: -75px;
        top: 2vmin;
        left: -7.5vmin;
        transform: rotate(calc(-25deg));
      }
      // bottom card
      &.card-1 {
        //  top: 60px;
        //  left: 40px;
        top: 6vmin;
        left: 4vmin;
      }
      // right card
      &.card-2 {
        //  top: 20px;
        //  left: 150px;
        top: 2vmin;
        left: 15vmin;
        transform: rotate(calc(25deg));
      }
      // top card
      &.card-3 {
        //  top: -10px;
        //  left: 40px;
        top: -1vmin;
        left: 4vmin;
        position: absolute;
      }

      @media (max-width: 700px) {
        &.card-0 {
          left: -8.5vmin;
        }
      }

      @media (max-width: 600px) {
        &.card-0 {
          left: -11.5vmin;
        }
      }

      @media (max-width: 500px) {
        &.card-0 {
          left: -18.5vmin;
        }
      }

      @media (max-width: 400px) {
        &.card-0 {
          left: -24.5vmin;
        }
      }

      @media (max-height: 800px) {
        width: 75px;
        height: 112px;
        background-size: 75px 112px;

        &.card-0 {
          top: 10px;
          left: -36px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 105px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 700px) {
        // left card
        &.card-0 {
          top: 10px;
          left: -36px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 95px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 600px) {
        width: 65px;
        height: 96px;
        background-size: 65px 96px;
        // left card
        &.card-0 {
          top: 10px;
          left: -13px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 90px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 450px) {
        width: 60px;
        height: 89px;
        background-size: 60px 89px;
      }
    }


    .table-card-bg-after {
      background-image: unset;
      border: 0.1vmin dashed rgba(255, 255, 255, 0.4);
      border-radius: 5px;
    }
  }

  @media screen and (orientation : portrait) {
    .table-cards {
      .table-card {
      }
    }
  }

  .played-cards {
    height: 30%;
    width: 20% !important;
    margin-left: 40% !important;
    top: unset !important;
    bottom: 34% !important;
    top: 25%;
    left: unset;
    width: 20%;
    min-width: 150px;
    max-width: 200px;
    margin-left: 45%;
    z-index: 899;
    position: absolute;
    //  margin: 0!important;
    //  margin-left: auto!important;
    //  margin-right: auto!important;

    & .card {
      //  width: 100px;
      //  height: 149px;

      width: $cardWidth;
      height: $cardHeight;
      min-width: 60px;
      min-height: 89px;
      background-color: transparent;
      float: left;
      margin-right: -58px;
      border: none;
      transition: none !important;
      cursor: pointer;
      background-size: 100% 100%;
      background-position: top center;
      background-repeat: no-repeat;
      // left card
      &.card-0 {
        top: 10px;
        left: -38px;
        position: absolute;
        //  z-index: 10;
      }
      // bottom card
      &.card-1 {
        top: 30px;
        left: 40px;
        position: absolute;
        //  z-index: 30;
      }
      // right card
      &.card-2 {
        top: 10px;
        left: 115px;
        position: absolute;
        //  z-index: 20;
      }
      // top card
      &.card-3 {
        top: -10px;
        left: 40px;
        position: absolute;
        //  z-index: 20;
      }

      @media (max-height: 800px) {
        width: 75px;
        height: 112px;
        background-size: 75px 112px;

        &.card-0 {
          top: 10px;
          left: -28px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 105px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 700px) {
        //  width: 60px;
        //  height: 89px;
        //  background-size: 60px 89px;
        // left card
        &.card-0 {
          top: 10px;
          left: -18px;
        }
        // bottom card
        &.card-1 {
          top: 30px;
          left: 40px;
        }
        // right card
        &.card-2 {
          top: 10px;
          left: 95px;
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 600px) {
        width: 65px;
        height: 96px;
        background-size: 65px 96px;
        // left card
        &.card-0 {
          left: -75px !important;
          top: 20px !important;
          transform: rotate(calc(-25deg));
        }
        // bottom card
        &.card-1 {
          top: 60px !important;
        }
        // right card
        &.card-2 {
          left: 150px !important;
          top: 20px !important;
          transform: rotate(calc(25deg));
        }
        // top card
        &.card-3 {
          top: -10px;
          left: 40px;
        }
      }

      @media (max-height: 450px) {
        width: 60px;
        height: 89px;
        background-size: 60px 89px;
      }


      &-0 {
        left: -75px !important;
        top: 20px !important;
        transform: rotate(calc(-25deg));
      }

      &-1 {
        top: 60px !important;
      }

      &-2 {
        left: 150px !important;
        top: 20px !important;
        transform: rotate(calc(25deg));
      }
    }
  }
}
