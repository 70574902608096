
// @import "../../redesignVariables";

// $bodyFontSize: 1.2vmin;
$bodyFontSize: 12px;
// $headerFontSize: 1.6vmin;
$headerFontSize: 16px;

$titlePadding: 1vmin 5px 0.2vmin 15px;

$rowHeight: 3.6vmin;

$rowBackground: rgba(255,255,255,0.03);
$highlightColor: #FFC900;

.root-class-design20 {

  .end-result {

    &-table {
      max-width: 100%;
      table-layout: fixed;
      width: 100%;

      &-scrollarea {
        margin-top: 10px;
        height: 300px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        &-scoretable {
          height: 22vh !important;
        }
      }

      &-header {
      //  width: 100%;
        height: auto;
        max-width: 100%;
        // overflow: hidden;
        margin-left: 15px;
        margin-right: 15px;

        font-weight: bold;
        font-size: var(--defaultFontSize);

        &-col {
          color: #FFF;
          width: 15%;

          font-weight: bold;
          font-size: var(--defaultFontSize);
          padding-left: 5px;
          padding-right: 5px;
          text-align: left;
          display: flex;
          align-items: center;
          &-last {
            text-align: right;
            
          }
        }
      }

      &-body {
      //  max-height: 300px;
      //  overflow-y: scroll;
      //  overflow-x: hidden;
        &-leave-room {
          font-size: var(--defaultFontSize);
        }
      }

      &-col {
        overflow: hidden;
        color: #fff;

        font-size: var(--defaultFontSize);
        line-height: 3.5vmin;
        text-align: left;
        vertical-align: middle;
        padding-left: 5px;
        padding-right: 5px;

        &-highlight {
          color: $highlightColor;
        }

        &-last {
          text-align: right;
          display: flex;
          justify-content: flex-end;
          & img {
            width: 32px;
            cursor: pointer;
            transform: scale(1.3);
            margin-right: 4px;
          }
        }

        &-positive {
          background: #009C10;
          border-radius: 44px;
          width: max-content;
          padding-left: 1.5vmin;
          padding-right: 1.5vmin;
        }

        &-negative {
          background: #D2110A;
          border-radius: 44px;
          width: max-content;
          padding-left: 1.5vmin;
          padding-right: 1.5vmin;
        }
      }

      &-row {
        //height: 4vmin;
        max-width: 100%;
        overflow: hidden;

        background: $rowBackground;
        border-radius: 44px;
        margin-bottom: 0.5vmin;

        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 0.25vmin;
        padding-top: 0.25vmin;

        &-user {
          height: 34px;
          max-width: 100%;
          overflow: hidden;
          background: rgba(247, 180, 90, 0.3);
        }
      }

      &-score {
        & .scrollArea-buttonUp {
          right: 15px !important;
          top: 32px !important;
        }
        & .scrollArea-buttonDown {
          right: 15px !important;
          bottom: 27px !important;
        }
      }

      &-close-info {
        font-size: var(--largeFontSize);
        font-weight: 600;
        // padding-top: 5px;
        padding: 10px 20px;
        background: #FF8A1A;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row;
    
        // & .
        & .info-image{
          width: 20%;
        }
        & .close-reason {
          width: 80%;
        }
      }
    }

    &-score-table-sum {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      // min-height: 196px;
    }
  }

  .partylog-scrollarea {
    overflow: hidden auto;
    max-height: calc(100vh - 250px);

    @media screen and (orientation: portrait) {
      max-height: calc(100vh - 310px);
    }
  }

  @media screen and (orientation: portrait) {
    .end-result-table-header {
    }
  }
  
  @media screen and (max-width: $mobileLandScapeWidth) and (orientation: landscape) {
    .end-result-table-header {
      margin-top: 1vh !important;
      height: auto !important;
      line-height: unset !important;
    }

    .end-result-table-row {
      padding-top: 0.5vh;
      padding-bottom: 0.5vh;
      margin-bottom: 0.5vh;
      height: auto !important;

    }

    .end-result-table-close-info {
      padding: 0.5vh 1vh;
      margin-top: 1vh;
    }
  }
}
