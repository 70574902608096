@import "./components/cards";
@import "./components/playedCards";
@import "./components/chat";
@import "./components/scoreTable";
@import "./components/topRow";
//@import "./components/turnTimer";
@import "./components/layout";
@import "./components/selectType";

@import "./components/sendGift";
@import "./components/previousRound";

@import "./components/player";
@import "./components/gameStats";
@import "./components/endResultModal";

html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
.root-class-design20 {

  .logo-wrapper {
    top: 15px;
    left: 15px;
    right: unset;
    width: auto;
  }

  .logo-domain {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--xLargeFontSize);
    color: #FED75F;
  }

  .loadingScreen {
    width: 100vw;
    height: 100vh;
    // background-image: url('../../../../images/redesign/landing-view/game_screen_bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    left: 0px;
    top: 0px;
    justify-content: center;
    align-items: center;

    &-loading {
      background-image: url('../../../../images/redesign/landing-view/zoleLogo.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 241px;
      height: 374px;
      background-position: center center;
      position: absolute;
      left: calc(100vw/2 - 120px);
      top: calc(100vh/2 - 185px);
    }

    &-spinner {
      width: 35rem;
      height: 35rem;
    }

    @media screen and (max-width: 1280px) {
      &-spinner-col {
        position: fixed;
        top: 35% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 400px;
        max-width: unset !important;
        padding: 0;

        @media screen and (max-height: 620px) {
          width: 250px !important;
          height: 250px !important;
          top: 30% !important;
        }

        &-iframe {
          top: 55% !important;
          left: 54%;
          width: 330px;
          height: 330px;
        }
      }

      &-spinner {
        width: 100%;
        height: 100%;

        &-iframe {
          width: 70%;
          height: 70%;
        }
      }

      &-loading {
        position: fixed;
        width: 170px !important;
        height: 264px !important;
        top: 35% !important;
        left: 50% !important;
        overflow: visible;
        transform: translate(-50%, -50%);

        @media screen and (max-height: 620px) {
          width: 85px !important;
          height: 132px !important;
          top: 30% !important;
        }

        &-iframe {
          width: 112px !important;
          height: 174px !important;
          top: 50% !important;
          left: 50% !important;
        }
      }

      &-domain {
        position: fixed !important;
        top: calc(35% + 145px) !important;
        left: 50% !important;
        transform: translate(-50%, -50%);

        @media screen and (max-height: 620px) {
          top: calc(30% + 80px) !important;
        }
      }
    }

    &-domain {
      position: absolute;
      left: calc(100vw/2 - 60px);
      top: calc(100vh/2 + 185px);
      font-size: var(--xxLargeFontSize);
      font-weight: bold;
      color: #fff;

      &-iframe {
        // left: calc(100vw/2 - 60px);
        top: calc(100vh/2 + 145px) !important;
      }
    }

    &-dg {
      position: absolute;
      left: calc(100vw/2 - 105px);
      top: calc(100vh/2 + 235px);
      background: #000000;
      filter: blur(15px);
      width: 200px;
      height: 20px;

      &-iframe {
        left: calc(100vw/2 - 80px);
        top: calc(100vh/2 + 55px);
        width: 150px;
        height: 15px;
      }
    }
  }



  .loading {
    &Screen {
      background-image: url('../../../../images/redesign/landing-view/game_screen_bg.webp');
    }
  }

  .game-stats-mobile-close-icon {
    display: none;
  }

  .mobile-player-progressbar {
    display: flex !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 40px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    z-index: 1;
    color: #fff;
    text-align: center;
    text-shadow: 2px 2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000;
  }

  @media screen and (orientation : portrait) {

    .modal-dialog {
      max-width: 90vw !important;
      margin: 0 auto;
    }

    .dropdown-menu-modal-content {
      & .close-button {
        top: 20px !important
      }
    }

    .logo-wrapper-mobile {
      position: unset;
      left: 20px !important;

      & .logo {
        position: absolute;
        top: 0;
      }
    }

    .game-bg-table-mobile {
      background-size: 175% 70%;
      top: -300px;
    }

    & .room-out {
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      pointer-events: all;

      & img {
        width: 18px;
        height: 32px;
      }
    }

    & .test-modal-button {
      position: fixed;
      top: 12px !important;
    }

    .active-room-footer {
      display: flex !important;
      flex-direction: row;
      position: fixed;
      height: 44px;
      bottom: 0px;
      z-index: 100;

      & .chat-link {
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        cursor: pointer;

        &-image {
          width: 22px;
          height: 19px;
        }
      }

      & .room-out {
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: calc(100vw / 4);
        pointer-events: all;

        &-image {
          width: 22px;
          height: 19px;
        }
      }

      & .game-score {
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: calc(100vw - 44px);
        cursor: pointer;

        &-image {
          width: 22px;
          height: 19px;
        }
      }
    }

    .score-table {
      width: 100% !important;
      max-width: 100% !important;
      right: 0px !important;
      z-index: 999 !important;

      & .score-table-top {
        background: rgba(0, 0, 0, 1);
      }

      & .score-table-bottom {
        background: rgba(0, 0, 0, 1);
        height: 6vmin;
      }
    }

    .snack-message {
      &-img {
        // position: fixed;
        // right: 0px;
        // bottom: 0px;
        // width: 25%;
        // z-index: 9999;
      }
      &-textbox {
        // position: fixed;
        // background: white;
        // border-radius: 19px;
        //right: 25%;
        bottom: 3px;
        // width: auto;
        // height: auto;
        // z-index: 9999;
        // padding: 10px;
        // font-size: var(--defaultFontSize);
        // text-align: right;
      }
    }

    .room-chat {
      width: 80%;
      bottom: 90px;
      z-index: 999;

      &-closed {
        bottom: calc(95px + 5vh) !important;
      }

      &-header {
        background: rgba(0,0,0,0.8);
        width: 190px;
        border-radius: 15px;
        padding: 3px;
      }
      // & .room-chat-header {
      //   display: none !important;
      // }

      & .room-chat-message-other {
        //background: rgba(0, 0, 0, 1);
        background: rgba(0, 0, 0, 0.9);
        display: flex;
        flex-direction: row;

        & img {
          width: 5vmin;
          height: 5vmin;
          // margin-top: 5px;
        }

        &-image-frame {
          width: calc(5vmin + 2px);
          height: calc(5vmin + 2px);
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        & .room-chat-message-other-text p {
          word-break: break-word;
        }

        & .room-chat-message-other-text span {
          color: #fff;
        }

        & .room-chat-message-other-text-light span {
          color: black;
        }

        & .room-chat-message-other-text-normal span {
          color: black;
        }

        & .room-chat-message-other-text-dark span {
          color: white;
        }
      }
    }

    .room-chat-open-mobile {
      display: block !important;
    }

    .room-chat-closed-mobile {
      display: none !important;
    }

    & .room-chat-bar {
      bottom: 50px;
      z-index: 999;
      width: 100%;
    }

    & .room-chat-bar-left {
      background: rgba(0, 0, 0, 1);
    }

    & .room-chat-bar-right {
      position: absolute !important;
      left: 18px !important;
      top: 48px !important;

      & .room-chat-bar-button-support {
        border: unset !important;
        background: rgba(0, 0, 0, 0.7);
      }
    }

    .room-chat-bar-mobile {
      display: block;
    }

    .room-chat-bar-mobile-close {
      display: none;
    }

    .emotions {
      width: 90vw;
    }

    .emotions-open {
      display: none !important;
    }

    .emotion-mobile {
      display: block !important;
      top: 120px !important;
    }

    .mobile-player-pos {
      height: 21vh !important;
      margin-left: 0px;
      margin-right: 0px;

      & .player-firstperson-background {
        height: 125px;
        bottom: 0px;
        position: fixed;
        width: 205px;

        & .player-firstperson-background-shadow2 {
          display: none;
        }

        & .player-firstperson-background-shadow {
          opacity: 0.4;
        }
      }

      & .mobile-player-main-pos {
        min-width: 130px;
        position: fixed;
        //left: calc(100vw / 2 - 65px) !important;
        left: 50%;
        transform: translateX(-50%) !important;
        width: unset !important;
        //transform: none;
        //position: fixed !important;
        pointer-events: none;
        * {
          pointer-events: auto;
        }

        & .player-image-timer-wrapper {
          & .player-info-icon {
            width: 42px;
            height: 42px;
            //top: 23%;
            top: 40%;
            right: 1%;
          }

          & .player-icon-gift {
            top: 60%;
            right: 5%;
            width: 42px;
            height: 42px;
          }
        }

        & .player-info-player-image-background {
          display: none;
        }

        & .player-type-mazais {
          min-width: 60px;
          min-height: 20px;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: -25px;
          z-index: 99;
          font-size: var(--defaultFontSize);
          font-weight: 600;
        }

        & .player-type-lielais {
          min-width: 60px;
          min-height: 20px;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: -25px;
          z-index: 99;
          font-size: var(--defaultFontSize);
          font-weight: 600;
        }

        & .player-type-none {
          display: none;
        }

        & .player-balance {
          //display: none;
          top: -28px !important;
        }

        & .player-name-wrapper {
          top: -30px;

          & .player-name {
            font-size: var(--defaultFontSize) !important;
            padding: 1px 1px !important;
          }
        }

        & .player-name-wrapper-active {
          & .player-name {
            background: rgba(210, 17, 10, 1);
          }
        }

        & .cards2-wrapper {
          // position: fixed;
          left: 0px;
          width: 100vw;

          & .last-round-icon {
            position: fixed;
            top: unset; //-150px;
            bottom: 33vh;
            right: 0px;
            width: 50px;
            height: 50px;
          }

          & .cards2 {
            left: 0px !important;

            & .card2 {
              min-width: 129px;
              min-height: 180px;

              &-on-table {
                min-width: 77px;
                min-height: 108px;
              }
            }

            & .card2-0 {
              left: 0px;
              top: 0px;
            }

            & .card2-1 {
              left: calc(100vw / 10);
              top: 0px;
            }

            & .card2-2 {
              left: calc(100vw / 10 * 2);
              top: 0px;
            }

            & .card2-3 {
              left: calc(100vw / 10 * 3);
              top: 0px;
            }

            & .card2-4 {
              left: calc(100vw / 10 * 4);
              top: 0px;
            }

            & .card2-5 {
              left: calc(100vw / 10 * 5);
              top: 0px;
            }

            & .card2-6 {
              left: calc(100vw / 10 * 6);
              top: 0px;
            }

            & .card2-7 {
              left: calc(100vw / 10 * 7);
              top: 0px;
            }

            & .card2-8 {
              left: calc(100vw / 10 * 8);
              top: 0px;
            }

            & .card2-9 {
              left: calc(100vw / 10 * 9);
              top: 0px;
            }
          }

          & .card2-wrapper {
            transform: unset;
            width: 129px;
            height: 189px;

            & .card2-innerWrapper {
              width: 129px;
            }
          }

          & .student-zole-cards {
            top: -420px !important;
            left: -15px !important;

            & .card2-wrapper {
              width: 77px;
              height: 108px;
              min-width: 77px;
              min-height: 108px;

              & .card2-innerWrapper {
                width: 77px;
                min-width: 77px;
              }
            }

            & .student-zole-card-0 {
              left: calc(100vw / 8 * 1);
              top: 0px;
            }

            & .student-zole-card-1 {
              left: calc(100vw / 8 * 2);
              top: 0px;
            }

            & .student-zole-card-2 {
              left: calc(100vw / 8 * 3);
              top: 0px;
            }

            & .student-zole-card-3 {
              left: calc(100vw / 8 * 4);
              top: 0px;
            }
          }
        }
      }
    }

    .player-right-mobile {
      top: 60px !important;
      right: 0px !important;

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -47px !important;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-name-wrapper {
        top: -50px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          width: 42px;
          height: 42px;
          //top: 46%;
          top: 55%;
          right: 3%;
          display: none;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          top: 40%;
          right: 1%;
        }

        & .block-icon {
          display: none;
        }

        & .unblock-icon {
          display: none;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
    }

    .player-left-mobile {
      top: 60px !important;
      left: 0px !important;

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -47px !important;
      }

      & .player-name-wrapper {
        top: -50px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          width: 42px;
          height: 42px;
          //top: 46%;
          top: 55%;
          right: 3%;
          display: none;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          top: 40%;
          right: 1%;
        }

        & .block-icon {
          display: none;
        }

        & .unblock-icon {
          display: none;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
    }

    .player-top-mobile {
      position: fixed;
      left: 50% !important;
      transform: translateX(-50%) scale(0.8);

      &-iframe {
        transform: translateX(-50%) scale(1);
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -47px !important;
      }

      & .player-name-wrapper {
        top: -50px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          width: 42px;
          height: 42px;
          //top: 46%;
          top: 55%;
          right: 3%;
          display: none;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          top: 40%;
          right: 1%;
        }

        & .block-icon {
          display: none;
        }

        & .unblock-icon {
          display: none;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
    }

    .blocked {
      &-overlay {
        background: rgba(255, 255, 255, 0.8) !important;
      }
    }

    .room-chat-open-button-icon {
      //display: none;
    }

    .previous-round {
      top: 53.3vh;
      left: 10px;
      cursor: pointer;
      width: 8vmin;
      height: 8vmin;

      &-modal {
        width: 98vw;
        max-width: 98vw
      }

      & .previous-round-image {
        width: 8vmin !important;
        height: 8vmin !important;
        background-size: 8vmin 8vmin !important;
        pointer-events: none;
      }
    }

    & .table-cards {
      //margin-left: 30% !important;
      transform: translate(-50%, 0);
      left: 50%;

      & .card-0 {
        left: -80px;
      }
      // & .card-1 {
      //   top: 6vmin !important;
      //   left: 4vmin !important;
      // }

      & .card-2 {
        left: 120px !important;
      }
    }

    .game-stats-row {
      //background: #000 !important;
      width: auto !important;
      min-height: 200px !important;
      position: fixed !important;
      top: 52px !important;
      z-index: 999 !important;
      border-radius: 19px !important;
      right: 5px;

      & .game-stats-inner {
        top: 10px;
      }

      & .game-stats-inner-tournament-header-optional {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
      }

      & .game-stats-mobile-close-icon {
        display: flex;
        position: absolute;
        right: -39px;
        top: -16px;
        height: 36px;
        width: 36px;
        opacity: 0.7;
        padding: 9px;
        background: rgba(0,0,0,0.7);
        border-radius: 50%;
        border: 1px solid #828282;
        cursor: pointer;
      }
    }

    .game-status-row-mobile-open {
      display: flex;
    }

    .game-status-row-mobile-close {
      display: none !important
    }

    .player-info-new-modal-backdrop {
      display: none !important;
    }

    .player-info-new-modal {
      display: none !important;
    }

    .mobile-player-info-modal {
      display: flex !important;
      top: 80px !important;
      width: 90vw;
      height: 75vh;

      .modal-title {
        display: none !important
      }

      & .player-info-new-header {
        top: unset !important;

        & .player-info-new-player {
          top: -65px !important;

          & .player-info-new-player-background {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & .player-info-new-player-image-background {
              display: none !important;
            }

            & .player-info-new-player-image {
              width: 100%;
              height: 100%;
              margin: unset !important;
            }

            & .player-info-new-player-name-wrapper {
              position: unset !important;
              margin-top: -12px;

              & .player-info-new-player-name {
                font-size: var(--largeFontSize);
              }
            }

            & .player-info-new-player-wrapper {
              background: #000;
              border-radius: 88px;
              padding: 3px 11px 3px 11px;
              position: unset !important;
              margin-top: 3px;

              & .player-info-new-player-balance-icon {
                height: 18px;
              }

              & .player-info-new-player-rating-icon {
                height: 18px;
              }

              & .player-info-new-player-balance {
                font-size: var(--largeFontSize);
                position: unset !important;
              }

              & .player-info-new-player-rating {
                font-size: var(--largeFontSize);
                position: unset !important;
              }
            }
          }
        }

        & .player-info-new-player-reg-date {
          display: flex;
          flex-direction: column;
          font-size: var(--smallFontSize);
          position: absolute;
          right: 0px;
          top: -33px;
          align-items: flex-end;
        }
      }

      & .player-info-new-body {
        width: 100%;
        height: 65vh !important;
        background: unset !important;
        border-radius: unset !important;
        box-shadow: unset !important;
        padding-top: 4.5vmin !important;

        & .player-info-new-body-content {
          margin-top: 50px;

          & div {
            padding-left: 0px;
            padding-right: 0px;

            & .player-info-new-tab-link {
              margin-left: 0px !important;

              & a {
                padding: 0px !important;
                margin-left: 0px !important;
                margin-right: 20px;
              }
            }

            & .giftsHistory-new {
              width: 64px;
              height: 64px;
            }

            & .giftsHistory-tab-new-row {
              height: calc(54vh - 30px);
            }

            & .player-info-statistics-scrollarea {
              overflow: hidden auto;
              height: calc(55vh - 60px);

              & .player-info-new-statistics-value {
                color: rgba(255, 201, 0, 1);
              }
            }

            & .player-info-footer {
              display: flex;
              justify-content: center;
              align-items: center;

              & img {
                width: 62px;
                height: 62px;
              }

              &-gift {
                //margin-top: 14px;
              }

              &-block {
                width: 40px !important;
                height: 40px !important;
                // margin-top: 7px;
                margin-top: -7px;
                cursor: pointer;
              }

              &-unblock {
                width: 62px !important;
                height: 62px !important;
                // margin-top: -5px;
                cursor: pointer;
              }
            }

            & .player-info-footer img {
            }
          }
        }
      }
    }

    .previous-round-player-photo {
      width: 100% !important;
      height: unset !important;
    }

    .send-gifts-section {
      display: none !important;
    }

    .send-gifts-confirmation {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--mLargeFontSize);
    }

    .send-gift-mobile-section {
      display: block !important;

      & .send-gifts {
        padding: unset !important;

        & .send-gifts-player {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);

          & .send-gifts-player-image {
            width: 7vmin !important;
            height: 7vmin !important;
          }

          & .send-gifts-player-everyone-image-wrapper {
            display: inline-flex;

            & .send-gifts-player-image-1 {
              margin-left: -18px;
            }

            & .send-gifts-player-image-2 {
              margin-left: -18px;
            }

            & .send-gifts-player-image-3 {
              margin-left: -18px;
            }
          }
        }

        & .send-gifts-list-mobile {
          display: flex !important;
          margin: 0 auto;
          background-color: #000;
          border-radius: 8px;
          justify-content: center;
          align-items: center;
          overflow-x: hidden;
          margin-bottom: 3px;
          width: 100%;

          & .send-gifts-list-mobile-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 9px 0px;
            max-height: 174px;
            justify-content: center;

            & .gifts-gift-item {
              position: relative;

              &-selected {
                box-shadow: 0px 0px 10px 2px #FFC900;
                border-radius: 50%;
              }

              & .gifts-gift-image {
                position: absolute;
                left: 0px;
                top: 0px;
              }
            }
          }

          & .send-gifts-list-mobile-section::-webkit-scrollbar {
            height: 0px;
          }
        }
      }
    }

    .select-type {
      //width: 100% !important;
      //left: 0 !important;
      top: 40px !important;

      & .select-type-button {
        width: 45%;
      }
    }

    .player-timer-circle {
      display: none;
    }

    .end-result-table-score {
      & .end-result-table-scrollarea {
        max-height: 100% !important;
        height: 165px !important;

        & .end-result-table-row {
          height: 10vmin !important;

          & .end-result-table-col {
            display: flex;
            //justify-content: center;
            align-items: center;
          }

          & .player-history-table-col-positive {
            height: 36px;
            width: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          & .player-history-table-col-negative {
            height: 36px;
            width: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .end-result-score-table-sum {
      & .player-history-table-col-positive {
        height: 36px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .player-history-table-col-negative {
        height: 36px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .end-result-table-header-landscape {
      display: none;
    }

    .score-table-toggle-closed {
      display: none !important;
    }

    .mobile-player-progressbar {
      display: flex !important;
      position: absolute;
      top: 30px;
      // left: 45px;
      font-size: 28px;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      z-index: 999;
      color: #fff;
      text-align: center;
      transform: translateY(0px);
    }


    .contact-admin-modal {
      max-width: 90vw !important;
      width: 90vw !important;
      top: 10px !important;

      & .contact-support-chat-description {
        padding-bottom: 0px !important;
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .logo-wrapper-mobile {
      position: unset;

      & .logo {
        position: absolute;
        top: 0;
      }
    }

    .game-bg-table-mobile {
      bottom: -150px;
      top: -150px;
    }

    .active-room-footer {
      display: flex !important;
      flex-direction: row;
      position: fixed;
      height: 44px;
      bottom: 0px;
      z-index: 999;

      & .chat-link {
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        cursor: pointer;

        &-image {
          width: 22px;
          height: 19px;
        }
      }

      & .room-out {
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: calc(100vw / 6 * 4);
        pointer-events: all;

        &-image {
          width: 22px;
          height: 19px;
        }
      }

      & .test-modal-button {
        position: fixed;
        top: 12px !important;
        left: 150px !important;
      }

      & .game-score {
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: calc(100vw - 44px);
        cursor: pointer;

        &-image {
          width: 22px;
          height: 19px;
        }
      }
    }

    .snack-message {
      &-img {
        // position: fixed;
        // right: 0px;
        // bottom: 0px;
        width: 15%;
        // z-index: 9999;
      }
      &-textbox {
        &-arrow {
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid white;
          border-bottom: unset;
          right: calc(20% - 20px);
          bottom: -20px;
          top: unset;
        }
        // position: fixed;
        // background: white;
        // border-radius: 19px;
        right: 3px;
        bottom: calc(5vh + 150px);
        // width: auto;
        // height: auto;
        // z-index: 9999;
        // padding: 10px;
        // font-size: var(--defaultFontSize);
        // text-align: right;
      }
    }

    .score-table {
      width: 60%;
      max-width: 50%;
      right: 0px;
      z-index: 999;

      & .score-table-top {
        background: rgba(0, 0, 0, 1);
      }

      & .score-table-bottom {
        background: rgba(0, 0, 0, 1);
        height: 6vmin;
      }
    }
    // .room-chat-header {
    //   display: none !important;
    // }

    .mobile-top-game-setting {
      display: flex !important;
      flex-direction: column-reverse;
    }

    .top-row-mobile-landscape {
      display: flex;

      & .top-setting-menu-item-container-holiday {
        // display: none !important;
        z-index: 9999;
      }

      & .mobile-top-setting {
        display: flex !important;
        position: fixed;
        flex-direction: column-reverse;
        right: 0px;
        align-items: center;

        &-iframe {
          gap: 35px;
          pointer-events: none;
        }
      }

      & .top-game-info {
        cursor: pointer;
        pointer-events: all;

        img {
          width: 40px;
          height: 40px;
        }
      }

      & .room-out {
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        pointer-events: all;

        & img {
          width: 18px;
          height: 32px;
        }
      }

      & .tutorial-link {
        padding: 5px 0;
      }

      & .dropdown-menu-link {
        padding: 2px 5px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
      }
    }

    .room-chat-open-button-icon {
      //display: none;
    }

    .room-chat {
      width: 48%;
      bottom: 90px;
      z-index: 999;

      &-closed {
        bottom: calc(95px + 5vh) !important;
      }

      &-header {
        background: rgba(0,0,0,0.8);
        width: 190px;
        border-radius: 15px;
        padding: 3px;
      }
      // & .room-chat-header {
      //   display: none !important;
      // }

      & .room-chat-message-other {
        //background: rgba(0, 0, 0, 1);
        background: rgba(0, 0, 0, 0.9);

        & .room-chat-message-other-text p {
          word-break: break-word;
        }

        & .room-chat-message-other-text span {
          color: #fff;
        }

        & .room-chat-message-other-text-light span {
          color: black;
        }

        & .room-chat-message-other-text-normal span {
          color: black;
        }

        & .room-chat-message-other-text-dark span {
          color: white;
        }
      }
    }

    .room-chat-open-mobile {
      display: block !important;
    }

    .room-chat-closed-mobile {
      display: none !important;
    }

    & .room-chat-bar {
      bottom: 50px;
      z-index: 999;
      width: 60%;
    }

    & .room-chat-bar-left {
      background: rgba(0, 0, 0, 1);
    }

    & .room-chat-bar-right {
      position: absolute !important;
      left: 30px !important;
      top: 44px !important;

      & .room-chat-bar-button-support {
        border: unset !important;
        background: rgba(0, 0, 0, 0.7);
      }
    }

    .room-chat-bar-mobile {
      display: block;
    }

    .room-chat-bar-mobile-close {
      display: none;
    }

    .chat-body-scroll-area {
      //height: 100px !important;
    }

    .emotions {
      width: 90vw;
    }

    .emotions-open {
      display: none !important;
    }

    .emotion-mobile {
      display: block !important;
      // top: 120px !important;
      top: 45% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      position: absolute;
    }

    .mobile-player-pos {
      height: 21vh !important;
      margin-left: 0px;
      margin-right: 0px;

      & .player-firstperson-background {
        height: 125px;
        bottom: 0px;
        position: fixed;
        width: 205px;
        left: unset !important;
        right: -80px !important;

        & .player-firstperson-background-shadow2 {
          display: none;
        }

        & .player-firstperson-background-shadow {
          opacity: 0.4;
        }
      }

      & .mobile-player-main-pos {
        min-width: 130px;
        position: fixed;
        right: 39px;
        left: unset !important;
        bottom: -2% !important;
        transform: none;
        //position: fixed !important;

        & .player-image-timer-wrapper {
          & .player-info-icon {
            width: 42px;
            height: 42px;
            //top: 23%;
            top: 40%;
            right: 1%;
          }

          & .player-icon-gift {
            top: 60%;
            right: 5%;
            width: 42px;
            height: 42px;
          }
        }

        & .player-info-player-image-background {
          display: none;
        }

        & .player-type-mazais {
          min-width: 60px;
          min-height: 20px;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: -25px;
          z-index: 99;
          font-size: var(--defaultFontSize);
          font-weight: 600;
        }

        & .player-type-lielais {
          min-width: 60px;
          min-height: 20px;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: -25px;
          z-index: 99;
          font-size: var(--defaultFontSize);
          font-weight: 600;
        }

        & .player-type-none {
          display: none;
        }

        & .player-balance {
          //display: none;
          top: -28px !important;
        }

        & .player-name-wrapper {
          top: -30px;

          & .player-name {
            font-size: var(--defaultFontSize) !important;
            padding: 1px 1px !important;
          }
        }

        & .player-name-wrapper-active {
          & .player-name {
            background: rgba(210, 17, 10, 1);
          }
        }

        & .cards2-wrapper {
          position: fixed;
          left: -55px;
          width: 100vw;
          bottom: 68px;

          & .last-round-icon {
            top: unset;
            bottom: 16vh;
            right: 19.5%;
            width: 11vmin;
            height: 11vmin;
          }

          & .cards2 {
            left: 0px !important;

            & .card2 {
              min-width: 129px;
              min-height: 180px;

              &-on-table {
                min-width: 84px;
                min-height: 117px;
              }
            }

            & .card2-0 {
              left: calc(75vw / 10);
              top: 0px;
            }

            & .card2-1 {
              left: calc(75vw / 10 * 2);
              top: 0px;
            }

            & .card2-2 {
              left: calc(75vw / 10 * 3);
              top: 0px;
            }

            & .card2-3 {
              left: calc(75vw / 10 * 4);
              top: 0px;
            }

            & .card2-4 {
              left: calc(75vw / 10 * 5);
              top: 0px;
            }

            & .card2-5 {
              left: calc(75vw / 10 * 6);
              top: 0px;
            }

            & .card2-6 {
              left: calc(75vw / 10 * 7);
              top: 0px;
            }

            & .card2-7 {
              left: calc(75vw / 10 * 8);
              top: 0px;
            }

            & .card2-8 {
              left: calc(75vw / 10 * 9);
              top: 0px;
            }

            & .card2-9 {
              left: calc(75vw / 10 * 10);
              top: 0px;
            }
          }

          & .card2-wrapper {
            transform: unset;
            width: 129px;
            height: 189px;

            & .card2-innerWrapper {
              width: 129px;
            }
          }

          & .student-zole-cards {
            //  top: -520px !important;
            left: 20px !important;

            & .card2-wrapper {
              width: 84px;
              height: 117px;
              min-width: 84px;
              min-height: 117px;

              & .card2-innerWrapper {
                width: 84px;
                min-width: 84px;
              }
            }

            & .student-zole-card-0 {
              left: calc(100vw / 9 * 1);
              top: 0px;
            }

            & .student-zole-card-1 {
              left: calc(100vw / 9 * 2);
              top: 0px;
            }

            & .student-zole-card-2 {
              left: calc(100vw / 9 * 3);
              top: 0px;
            }

            & .student-zole-card-3 {
              left: calc(100vw / 9 * 4);
              top: 0px;
            }
          }
        }
      }
    }

    .player-left-mobile {
      position: fixed;
      margin-top: 0px;
      top: 0px !important;
      //left: calc(50vw - 75vh) !important;
      left: 50px !important;

      @media screen and (max-height: 400px) {
        left: calc(50vw - 95vh) !important;
      }

      @media screen and (min-height: 400px) and (max-height: 500px) {
        left: calc(50vw - 90vh) !important;
      }

      @media screen and (min-height: 500px) and (max-height: 550px) {
        left: calc(50vw - 85vh) !important;
      }

      @media screen and (min-height: 550px) and (max-height: 650px) {
        left: calc(50vw - 80vh) !important;
      }

      &-iframe-4 {
        // top: 12% !important;
        // left: 5.5% !important;
        top: 15% !important;
        left: 2% !important;

        @media (max-width: 980px) {
          // top: 12% !important;
          left: 0.5% !important;
        }
      }

      &-iframe-3 {
        // top: 12% !important;
        // left: 5.5% !important;
        top: 17% !important;
        left: 6% !important;

        @media (max-width: 980px) {
          // top: 12% !important;
          left: 1% !important;
        }
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -28px !important;
      }

      & .player-name-wrapper {
        top: -30px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          width: 42px;
          height: 42px;
          //top: 46%;
          top: 55%;
          right: 3%;
          display: none;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          right: 0%;
          top: 22%;
        }

        & .block-icon {
          display: none;
        }

        & .unblock-icon {
          display: none;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
    }

    .player-right-mobile {
      position: fixed;
      margin-top: 0px;
      top: 0px !important;
      //right: calc(50vw - 75vh) !important;
      right: 50px !important;

      @media screen and (max-height: 400px) {
        right: calc(50vw - 95vh) !important;
      }

      @media screen and (min-height: 400px) and (max-height: 500px) {
        right: calc(50vw - 90vh) !important;
      }

      @media screen and (min-height: 500px) and (max-height: 550px) {
        right: calc(50vw - 85vh) !important;
      }

      @media screen and (min-height: 550px) and (max-height: 650px) {
        right: calc(50vw - 80vh) !important;
      }

      &-iframe-4 {
        // top: 12% !important;
        //right: 5.5% !important;
        top: 15% !important;
        right: 2% !important;

        @media (max-width: 980px) {
          // top: 12% !important;
          right: 0.5% !important;
        }
      }

      &-iframe-3 {
        top: 17% !important;
        right: 6% !important;

        @media (max-width: 980px) {
          right: 1% !important;
        }
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -28px !important;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-name-wrapper {
        top: -30px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          display: none;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          right: 0%;
          top: 22%;
        }

        & .block-icon {
          display: none;
        }

        & .unblock-icon {
          display: none;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
    }

    .player-top-mobile {
      left: 50% !important;
      transform: translateX(-50%) scale(0.8);
      position: fixed;
      top: -30px !important;

      &-iframe {
        transform: translateX(-50%) scale(1);
        top: 15px !important;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -47px !important;
      }

      & .player-name-wrapper {
        top: -40px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          display: none;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          right: 0%;
          top: 22%;
        }

        & .block-icon {
          display: none;
        }

        & .unblock-icon {
          display: none;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
    }

    .blocked {
      &-overlay {
        background: rgba(255, 255, 255, 0.8) !important;
      }

      &-players-modal {
        .modal-body {
          flex: none !important;
        }
      }
    }

    .room-chat-open-button-icon {
      //display: none;
    }

    .previous-round {
      top: unset;
      bottom: calc(100px + 20vmin);
      left: 33%;
      right: unset;
      cursor: pointer;
      width: 8vmin;
      height: 8vmin;


      &-modal {
        width: 85vw;
        max-width: 85vw
      }

      & .previous-round-image {
        width: 8vmin !important;
        height: 8vmin !important;
        background-size: 8vmin 8vmin !important;
        pointer-events: none;
      }
    }

    & .table-cards {
      //margin-left: 30% !important;
      transform: translate(-50%, 0);
      left: 50%;

      & .card-0 {
      }

      & .card-0 {
      }
      // & .card-1 {
      //   top: 6vmin !important;
      //   left: 4vmin !important;
      // }

      & .card-2 {
      }
    }

    @media screen and (max-height: 600px) {
      left: -11px !important
    }

    .game-stats-row {
      //background: #000 !important;
      width: auto !important;
      min-height: 200px !important;
      position: fixed !important;
      top: 52px !important;
      z-index: 999 !important;
      border-radius: 19px !important;
      right: 5px;

      & .game-stats-inner {
        top: 10px;
      }

      & .game-stats-inner-tournament-header-optional {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
      }

      & .game-stats-mobile-close-icon {
        display: flex;
        position: absolute;
        right: -39px;
        top: -16px;
        height: 36px;
        width: 36px;
        opacity: 0.7;
        padding: 9px;
        background: rgba(0,0,0,0.7);
        border-radius: 50%;
        border: 1px solid #828282;
        cursor: pointer;
      }
    }

    .game-status-row-mobile-open {
      display: flex;
      right: 40px;
      //width: 50% !important;
      top: 15px !important;
    }

    .game-status-row-mobile-close {
      display: none !important
    }

    .player-info-new-modal-backdrop {
      display: none !important;
    }

    .player-info-new-modal {
      display: none !important;
    }

    .mobile-player-info-modal {
      display: flex !important;
      top: 0px !important;
      width: 90vw;
      height: 75vh;

      .modal-title {
        display: none !important
      }

      & .player-info-new-header {
        top: unset !important;

        & .player-info-new-player {
          top: -30px !important;

          & .player-info-new-player-background {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & .player-info-new-player-image-background {
              display: none !important;
            }

            & .player-info-new-player-image {
              width: 100%;
              height: 100%;
              margin: unset !important;
            }

            & .player-info-new-player-name-wrapper {
              position: unset !important;
              margin-top: -12px;

              & .player-info-new-player-name {
                font-size: var(--largeFontSize);
              }
            }

            & .player-info-new-player-wrapper {
              background: #000;
              border-radius: 88px;
              padding: 3px 11px 3px 11px;
              position: unset !important;
              margin-top: 3px;

              & .player-info-new-player-balance-icon {
                height: 18px;
              }

              & .player-info-new-player-rating-icon {
                height: 18px;
              }

              & .player-info-new-player-balance {
                font-size: var(--largeFontSize);
                position: unset !important;
              }

              & .player-info-new-player-rating {
                font-size: var(--largeFontSize);
                position: unset !important;
              }
            }
          }
        }

        & .player-info-new-player-reg-date {
          display: flex;
          flex-direction: column;
          font-size: var(--smallFontSize);
          position: absolute;
          right: 0px;
          top: -33px;
          align-items: flex-end;
        }
      }

      & .player-info-new-body {
        width: 100%;
        height: 65vh !important;
        background: unset !important;
        border-radius: unset !important;
        box-shadow: unset !important;
        padding-top: 4.5vmin !important;

        & .player-info-new-body-content {
          margin-top: 80px;
          width: 90% !important;

          & div {
            padding-left: 0px;
            padding-right: 0px;

            & .player-info-new-tabs {
              text-align: center;
            }

            & .player-info-new-tab-link {
              margin-left: 0px !important;

              & a {
                padding: 0px !important;
                margin-left: 0px !important;
                margin-right: 20px;
              }
            }

            & .giftsHistory-new {
              width: 64px;
              height: 64px;
            }

            & .giftsHistory-tab-new-row {
              height: calc(55vh - 100px);
            }

            & .player-info-statistics-scrollarea {
              overflow: hidden auto;
              height: calc(55vh - 100px);

              & .player-info-new-statistics-value {
                color: rgba(255, 201, 0, 1);
              }
            }

            & .player-info-footer {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: -50px;
              top: -40px;
              flex-direction: column;

              & img {
                width: 62px;
                height: 62px;
              }

              &-gift {
                margin-top: 14px;
              }

              &-block {
                width: 40px !important;
                height: 40px !important;
                margin-top: 7px;
                cursor: pointer;
              }

              &-unblock {
                width: 62px !important;
                height: 62px !important;
                // margin-top: -5px;
                cursor: pointer;
              }
            }

            & .player-info-footer img {
            }
          }
        }
      }
    }

    .send-gifts-section {
      display: none !important;
    }

    .send-gifts-confirmation {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--mLargeFontSize);
    }

    .contact-admin-modal {
      max-width: 75vw !important;
      width: 75vw !important;
      top: -10px !important;

      & .contact-support-chat-description {
        padding-bottom: 0px !important;
      }
    }

    .previous-round-section {
      height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .previous-round-player-photo {
      width: 100% !important;
      height: unset !important;
    }

    .previous-round-player-name {
      flex: 0 0 73.33333%;
      max-width: 73.33333%;
    }

    .previous-round-cards {
      height: 75px;

      & .previous-round-card {
        background-size: 50px 70px;
        width: 50px;
        height: 70px;
      }

      & .previous-round-card-1 {
        left: 13px;
      }

      & .previous-round-card-2 {
        left: 26px;
      }

      & .previous-round-card-3 {
        left: 39px;
      }

      & .previous-round-card-4 {
        left: 52px;
      }

      & .previous-round-card-5 {
        left: 65px;
      }

      & .previous-round-card-6 {
        left: 78px;
      }

      & .previous-round-card-7 {
        left: 91px;
      }

      & .previous-round-card-8 {
        left: 104px;
      }

      & .previous-round-card-9 {
        left: 117px;
      }

      & .previous-round-card-10 {
        left: 130px;
      }
    }

    .previous-round-burried-cards {
      height: 75px;
      width: 4vmin;

      & .previous-round-taken-card {
        background-size: 50px 70px;
        width: 50px;
        height: 70px;
      }

      & .previous-round-taken-card-1 {
        left: 15px;
      }

      & .previous-round-taken-card-2 {
        left: 26px;
      }
    }

    .previous-round-taken-cards {
      height: 75px;

      & .previous-round-taken-card {
        background-size: 50px 70px;
        width: 50px;
        height: 70px;
      }

      & .previous-round-taken-card-1 {
        left: 18px;
      }

      & .previous-round-taken-card-2 {
        left: 36px;
      }
    }

    .previous-round-table-cards {
      height: 75px;

      & .previous-round-card {
        background-size: 50px 70px;
        width: 50px;
        height: 70px;
      }

      & .previous-round-card-1 {
        left: 13px;
      }

      & .previous-round-card-2 {
        left: 26px;
      }
    }

    .game-result-mobile-landscape {
      display: block !important;
      // height: 40vh;
      // overflow-y: auto;
      // overflow-x: hidden;
    }

    .game-result-section {
      display: none !important;
    }

    .end-result-table-score {
      & .end-result-table-header {
        // display: none !important;
      }

      & .end-result-table-scrollarea {
        max-height: unset;
        height: auto;

        & .end-result-table-body {
          overflow: unset;

          & .end-result-table-row {
            height: 36px;
          }
        }

        &-scoretable {
          height: calc(50vh - 75px) !important;
          min-height: 75px;
          margin-top: 0px !important;
        }
      }
    }
    // .select-type {
    //   display: none !important;
    // }

    .player-timer-circle {
      display: none;
    }

    .score-table-toggle-closed {
      display: none !important;
    }

    .mobile-player-progressbar {
      display: flex !important;
      position: absolute;
      top: 30px;
      // left: 45px;
      font-size: 28px;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      z-index: 999;
      color: #fff;
      text-align: center;
      transform: translateY(0px);
    }

    .turn-timer-wrapper {
      .player-info-player {
        top: 3px;
        left: 3px;

        & .player-info-player-background {
          width: 95%;

          & .player-info-player-image-wrapper {
            width: 95%;
          }
        }
      }
    }

    .send-gift-mobile-section {
      display: block !important;
      min-width: 90vw;
      width: 90vw;
      max-width: 90vw;

      & .modal-content {
        & .modal-body {
          padding-top: 25px !important;
          padding-bottom: 3px !important;
        }

        & .modal-footer {
          padding-top: 3px !important;
          padding-bottom: 3px !important;
        }
      }

      & .send-gifts {
        padding: unset !important;

        & .send-gifts-player {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          flex-wrap: unset;
          min-height: auto;

          &-section {
            // width: 200px;
            // max-width: 300px;
            // min-width: 150px;
            width: 33%;
            max-width: 33%;
            min-width: 33%;
            justify-content: center !important;

            label {
              margin-bottom: 6px;
            }
          }

          & .send-gifts-player-image {
            width: 36px !important;
            height: 36px !important;
          }

          & .send-gifts-player-everyone-image-wrapper {
            display: inline-flex;

            & .send-gifts-player-image-1 {
              margin-left: -18px;
            }

            & .send-gifts-player-image-2 {
              margin-left: -18px;
            }

            & .send-gifts-player-image-3 {
              margin-left: -18px;
            }
          }
        }

        & .send-gifts-player::-webkit-scrollbar {
          height: 0px;
        }

        & .send-gifts-list-mobile {
          display: flex !important;
          margin: 0 auto;
          background-color: #000;
          border-radius: 8px;
          justify-content: center;
          align-items: center;
          overflow-x: hidden;
          margin-bottom: 3px;
          width: 100%;

          & .send-gifts-list-mobile-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 9px 0px;
            //max-height: 130px;
            max-height: calc(100vh - 268px);
            justify-content: center;

            & .gifts-gift-item {
              position: relative;

              &-selected {
                box-shadow: 0px 0px 10px 2px #FFC900;
                border-radius: 50%;
              }

              & .gifts-gift-image {
                position: absolute;
                left: 0px;
                top: 0px;
              }
            }
          }

          & .send-gifts-list-mobile-section::-webkit-scrollbar {
            height: 0px;
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) and (min-height: 720px) {

    .player-left-mobile {
      // left: -55px !important;
      // top: 70px !important;

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -28px !important;
      }

      & .player-name-wrapper {
        top: -30px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          display: none;
        }

        & .player-left-gift {
          display: none;
          // top: 40px;
          //left: 120px;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          right: 0%;
          top: 22%;
        }

        & .block-icon {
          display: none;
          // width: 42px;
          // height: 42px;
          // //top: 23%;
          // top: 40%;
          // right: 1%;
          // display: block;
        }

        & .unblock-icon {
          display: none;
          // display: block;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
      // & .player-left-emotion {
      //   top: 20px;
      // }
      // & .player-left-emotion-new-gift-enabled {
      //   top: 92px;
      // }
    }

    .player-right-mobile {
      // top: 70px !important;
      // right: -75px !important;

      & .player-info-player-image-background {
        display: none;
      }

      & .player-info-player-image-background {
        display: none;
      }

      & .player-type-mazais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%); //
        left: 50%; //
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-lielais {
        min-width: 60px;
        min-height: 20px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -25px;
        z-index: 99;
        font-size: var(--defaultFontSize);
        font-weight: 600;
      }

      & .player-type-none {
        display: none;
      }

      & .player-balance {
        //display: none;
        top: -47px !important;
      }

      & .player-type-wrapper {
        //left: -30px;
      }

      & .player-name-wrapper {
        top: -50px;

        & .player-name {
          font-size: var(--defaultFontSize) !important;
          padding: 1px 1px !important;
        }
      }

      & .player-name-wrapper-active {
        & .player-name {
          background: rgba(210, 17, 10, 1);
        }
      }

      & .player-image-timer-wrapper {
        & .player-icon-gift {
          display: none;
        }

        & .player-right-gift {
          // top: 40px;
          //right: 120px;
        }

        & .player-info-icon {
          width: 42px;
          height: 42px;
          right: 0%;
          top: 22%;
        }

        & .block-icon {
          display: none;
          // width: 42px;
          // height: 42px;
          // //top: 23%;
          // top: 40%;
          // right: 1%;
          // display: none;
        }

        & .unblock-icon {
          display: none;
        }

        & .report-player-icon img {
          width: 26px;
        }
      }
      // & .player-right-emotion {
      //   top: 20px
      // }
      // & .player-right-emotion-new-gift-enabled {
      //   top: 92px;
      // }
    }
  }
}

.root-class-design20-normal {
  @media screen and (orientation : portrait) {
    .game-stats-mobile-close-icon {
      filter: invert(1);
    }
    .score-table {
      & .score-table-top {
        background: rgba(255, 255, 255, 1) !important;
      }

      & .score-table-bottom {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-bar {
      &-left {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-message-other {
      background: rgba(255, 255, 255, 1) !important;
    }

    .player-info-new-body {
      color: #fff !important;
    }

    .player-info-body {
      background: transparent;
      box-shadow: none;
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .game-stats-mobile-close-icon {
      filter: invert(1);
    }
    .score-table {
      & .score-table-top {
        background: rgba(255, 255, 255, 1) !important;
      }

      & .score-table-bottom {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-bar {
      &-left {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-message-other {
      background: rgba(255, 255, 255, 1) !important;
    }

    .player-info-new-body {
      color: #fff !important;
    }

    .player-info-body {
      background: transparent;
      box-shadow: none;
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {

    .player-info-new-tab-link-text {
      color: black;
    }

    .score-table {
      & .score-table-top {
        background: rgba(255, 255, 255, 1) !important;
      }

      & .score-table-bottom {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-bar {
      &-left {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-message-other {
      background: rgba(255, 255, 255, 1) !important;
    }

    .active-room-footer {

      & .chat-link {
        background-color: rgba(255, 255, 255, 0.7);
      }

      & .room-out {
        background-color: rgba(255, 255, 255, 0.7);
      }

      & .game-score {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }

    .player-info-new-body {
      color: #000 !important;
    }

    .player-info-body {
      background: transparent;
      box-shadow: none;
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {

    & .room-chat-bar-right {
      & .room-chat-bar-button-support {
        border: unset !important;
        background: rgba(255, 255, 255, 0.7);
      }
    }

    .player-info-new-tab-link-text {
      color: black;
    }

    .score-table {
      & .score-table-top {
        background: rgba(255, 255, 255, 1) !important;
      }

      & .score-table-bottom {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-bar {
      &-left {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .room-chat-message-other {
      background: rgba(255, 255, 255, 1) !important;
    }

    .active-room-footer {
      & .chat-link {
        background-color: rgba(255, 255, 255, 0.7);
      }

      & .room-out {
        background-color: rgba(255, 255, 255, 0.7);
      }

      & .game-score {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }

    .player-info-new-body {
      color: #000 !important;
    }

    .player-info-body {
      background: transparent;
      box-shadow: none;
    }
  }
}


@media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
  .campaign-game-section.app-wrapper-not-fullscreen {
    .mobile-player-pos {
      .player-firstperson-background {
        // bottom: 50px;
        display: none;
      }

      .mobile-player-main-pos {
        bottom: 40px !important;

        .cards2-wrapper {
          bottom: 153px;
        }
      }
    }
  }
}

.root-is-app-frame {
  .send-gift-mobile-section {
    width: 72vw;
    max-width: 72vw;
    min-width: 72vw;

    & .modal-content {
      & .modal-body {
        padding-top: 41px !important;
      }

      & .modal-footer {
        padding-top: 10px !important;
        padding-bottom: 15px !important;
      }
    }
  }

  .app-wrapper-not-fullscreen.campaign-game-section {
    height: calc(100vh - 80px);

    .previous-round {
      bottom: calc(100px + 24vmin);
    }

    .mobile-player-pos {
      .player-firstperson-background {
        bottom: 50px;
      }

      .mobile-player-main-pos {
        bottom: 70px !important;

        @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
          .cards2-wrapper {
            bottom: 178px;
          }
        }
      }
    }

    .score-table {
      bottom: 150px !important;
    }

    .score-table-toggle-open-new {
      bottom: calc(215px + 24vh) !important
    }

    .score-table-toggle-closed-new {
      bottom: 134px !important;
    }

    .room-chat {
      bottom: 133px;
    }

    .room-chat-bar {
      // bottom: 133px !important;
    }

    .externalBannerRef {
      width: 100%;
      position: fixed;
      margin-left: 0;
      margin-right: 0;
      right: 0px;
      bottom: 0px;
    }
  }

}

.root-is-app-frame.app-wrapper-facebook-new {
  .score-table {
    bottom: 150px !important;
  }

  .score-table-toggle-open-new {
    bottom: calc(215px + 24vh) !important
  }

  .score-table-toggle-closed-new {
    bottom: 134px !important;
  }

  .room-chat {
    bottom: 210px !important;
  }

  .room-chat-closed {
    bottom: 245px !important;
  }

  .room-chat-bar {
    bottom: 143px !important;
  }

  .externalBannerRef {
    width: 100%;
    position: fixed;
    margin-left: 0;
    margin-right: 0;
    right: 0px;
    bottom: 65px;
  }

  .app-wrapper-not-fullscreen.campaign-game-section {
    .mobile-player-pos {
      .player-firstperson-background {
        bottom: 20px;
      }

      .mobile-player-main-pos {
        bottom: 70px !important;
        //  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        //    .cards2-wrapper {
        //      bottom: 163px;
        //    }
        //  }
      }

      @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .mobile-player-main-pos {
          bottom: 70px !important;

          .cards2-wrapper {
            bottom: 163px;
          }
        }
      }
    }


    .score-table {
      bottom: 83px !important;
    }

    .score-table-toggle-open-new {
      bottom: calc(160px + 24vh) !important
    }

    .score-table-toggle-closed-new {
      bottom: 84px !important;
    }

    .room-chat {
      bottom: 120px !important;
    }

    .room-chat-closed {
      bottom: 165px !important;
    }

    .room-chat-bar {
      bottom: 83px !important;
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
      .game-score {
        bottom: 75px;
      }

      .chat-link {
        bottom: 75px;
      }

      .score-table {
        bottom: 125px;
      }

      .room-chat {
        bottom: 160px !important;
      }

      .room-chat-bar {
        bottom: 123px !important;
      }

      .game-clock {
        bottom: 425px !important;
      }
    }
  }
}



  .root-class-design20:not(.root-is-app-frame) {

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
      .select-type {
        display: none !important;
      }

      .select-type-mobile-landscape {
        display: block !important;
        top: 0px !important;
        //width: 100% !important;
        //left: 0px !important;
        width: 600px !important;
        min-width: 600px;
        left: 50% !important;
        transform: translateX(-50%) !important;
        z-index: 999;

        & .select-type-button {
          width: 40% !important;
        }
      }
    }
  }
