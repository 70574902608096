

/*
@media only screen and (width: 768px) {
  .gift-modal-fullscreen {
    width: 90%!important;
    max-width: 90%!important;

    .gifts-gift {
      width: 33%;
    //  height: auto;
    }
  }
} */

.player-receivedGift-tooltip-new {
  .bs-tooltip-auto {
    .tooltip-inner {
      color: black;
      background-color: white;
      font-size: var(--defaultFontSize);
    }
    .arrow:before {
      border-bottom-color: #fff;
      border-top-color: #fff;
    }
  }
}
.player-receivedGift-tooltip-new-blink {
  .bs-tooltip-auto {
    animation: blink 1s infinite;
    .tooltip-inner {
      color: black;
      background-color: white;
      font-size: var(--defaultFontSize);
    }
    .arrow:before {
      border-bottom-color: #fff;
      border-top-color: #fff;
    }
  }
}
.player-receivedGift-tooltip-new-player-modal-right {
  .bs-tooltip-auto {
    z-index: 9999999999;
  }
}


.gifts-history-tooltip-old,
.player-receivedGift-tooltip-old {
  .bs-tooltip-auto {
    opacity: 1;
    .tooltip-inner {
      background-color: #2c5955;
      color: #fff;
      border: 1px solid #fff;
      box-shadow: 5px 5px 0px 0px #380000;
      border-radius: 0.3rem;
      font-size: var(--defaultFontSize);
    }
    .arrow:before {
      border-bottom-color: #fff;
      border-top-color: #fff;
    }
  }
}

.player-receivedGift-tooltip-old-blink {
  .bs-tooltip-auto {
    animation: blink 1s infinite;

    .tooltip-inner {
      background-color: #2c5955;
      color: #fff;
      border: 1px solid #fff;
      box-shadow: 5px 5px 0px 0px #380000;
      border-radius: 0.3rem;
      font-size: var(--defaultFontSize);
    }
    .arrow:before {
      border-bottom-color: #fff;
      border-top-color: #fff;
    }
  }
}


@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.gift-modal-fullscreen {
  @media (max-width: 768px) {
    width: 90%!important;
    max-width: 90%!important;

    .gifts-gift {
      width: 33%;
      background-size: auto auto;
    //  height: auto;

      &-image {
        max-width: 75%;
      }
    }
  }
}

.app-wrapper {
  width: 980px;
  height: 700px;
  max-width: 980px;

  &-inbox {
    width: 880px;
    height: 700px;
    max-width: 880px;
  }

  &.app-wrapper-fullscreen {
    width: 99% !important;
    height: 99%;
    //  min-width: 640px;
    min-width: 65vw;
    min-height: 710px;
    padding-right: 45px;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;

    .three-player-table {
      margin-left: -15px;
    }

    .four-player-table {
      margin-left: -15px;
    }

    .game-background {
      position: absolute;
      width: 100vw !important;
      height: 100vh !important;
      background-size: cover,cover;
    }

    .game-logo {
      height: 48px;
      width: 235px;
      margin-right: 30px;
    }

    .current-game-type {
      right: 285px;
    }

    .selection {
      position: fixed;
      top: 13%;
      margin-left: auto;
      margin-right: auto;
      width: 16%;
      max-width: 300px;
      min-width: 150px;

      @media (min-width: 1367px) {
        top: 13%;
      }

      @media (min-width: 1100px) {
        top: 10%;
      }

      @media (min-width: 500px) {
        top: 10%;
      }
    }


    .player-firstperson {
      left: 50%;
      transform: translateX(-50%);
      //bottom: 6%;
      bottom: 70px;
      top: unset;
      max-width: 155px;
      max-width: 10%;
      min-width: 110px;

      @media (max-height: 1000px) {
        max-width: 8.5%;
        //bottom: 5%;
      }

      @media (max-height: 800px) {
        max-width: 7.5%;
        //bottom: 4.5%;
      }

      @media (max-height: 700px) {
        max-width: 7.5%;
        //bottom: 4%;
      }

      @media (max-height: 600px) {
        max-width: 6%;
        //bottom: 3.2%;
      }

      @media (max-height: 500px) {
        max-width: 6%;
        // bottom: 2.5%;
      }

      @media (max-height: 500px) {
        max-width: 6%;
        // bottom: 2%;
      }
    }

    .player-left {
      left: 10%;
      top: 15%;
      max-width: 155px;
      max-width: 10%;
      min-width: 110px;

      @media (max-height: 1000px) {
        max-width: 8.5%;
      }

      @media (max-height: 800px) {
        max-width: 7.5%;
      }

      @media (max-height: 600px) {
        max-width: 6%;
      }
    }

    .player-right {
      right: 10%;
      top: 15%;
      max-width: 155px;
      max-width: 10%;
      min-width: 110px;

      @media (max-height: 1000px) {
        max-width: 8.5%;
      }

      @media (max-height: 800px) {
        max-width: 7.5%;
      }

      @media (max-height: 600px) {
        max-width: 6%;
      }
    }

    .player-top {
      left: 50%;
      transform: translateX(-50%);
      top: 7%;
      max-width: 155px;
      max-width: 10%;
      min-width: 110px;

      @media (max-height: 1000px) {
        max-width: 8.5%;
      }

      @media (max-height: 800px) {
        max-width: 7.5%;
      }

      @media (max-height: 600px) {
        max-width: 6%;
      }
      // &-gift {
      //   position: absolute;
      //   top: 25px;
      //   right: 160px;
      // }
    }

    .player-image-timer-wrapper {
      width: 100%;
      height: 100%;
      // min-width: 8vw;
      max-width: 150px;
      max-height: 150px;

      &-new {
        max-width: unset;
        max-height: unset;
      }

      @media (max-height: 1000px) {
        // min-width: 7vw;
      }

      @media (max-height: 800px) {
        // min-width: 6vw;
      }

      @media (max-height: 600px) {
        // min-width: 5vw;
      }
      //  min-height: 8vw;
      //  border: 1px solid yellow;
    }

    .turn-timer {

      &-wrapper {
        //  top: 0px;
        top: -3px;
        left: -1px;
        //  left: 0px;
        //  width: 100%;
        //  height: 100%;
        //  position: absolute;
        //  z-index: 10;
        //  .timer {
        //    left: 1px;
        //  top: 3px;
        //  }
      }
    }

    .player-avatar-wrapper {
      width: 100%;
      height: 100%;
      // min-width: 8vw;
      //  min-height: 8vw;
      padding: 0;
      //  border: 1px solid blue;
      // @media (max-height: 1000px) {
      //   min-width: 7vw;
      // }
      // @media (max-height: 800px) {
      //   min-width: 6vw;
      // }
      // @media (max-height: 600px) {
      //   min-width: 5vw;
      // }
    }

    .player-avatar {
      width: 100%;
      height: 100%;
      // min-width: 8vw;
      //  min-height: 8vw;
      //  border: 1px solid green;

      @media (max-height: 1000px) {
        min-width: 7vw;
      }

      @media (max-height: 800px) {
        min-width: 6vw;
      }

      @media (max-height: 600px) {
        min-width: 5vw;
      }

      & img {
        width: 100%;
        height: 100%;
        // min-width: 8vw;
        // @media (max-height: 1000px) {
        //   min-width: 7vw;
        // }
        // @media (max-height: 800px) {
        //   min-width: 6vw;
        // }
        // @media (max-height: 600px) {
        //   min-width: 5vw;
        // }
        //  min-height: 8vw;
        //  border: 1px solid red;
      }
    }

    .player-type-wrapper {
      width: 100%;
      // min-width: 8vw;
      // @media (max-height: 1000px) {
      //   min-width: 7vw;
      // }
      // @media (max-height: 800px) {
      //   min-width: 6vw;
      // }
      // @media (max-height: 600px) {
      //   min-width: 5vw;
      // }
    }

    .player-name-wrapper {
      //width: calc(100% + 200px); // NOTE: not sure why this was added, causes misaligned player names in old design game screen
      //right: 100px;
      // min-width: 8vw;
      // @media (max-height: 1000px) {
      //   min-width: 7vw;
      // }
      // @media (max-height: 800px) {
      //   min-width: 6vw;
      // }
      // @media (max-height: 600px) {
      //   min-width: 5vw;
      // }
      width: 200px;
      transform: translateX(-50%);
      left: 50%;
      top: -30px;
    }

    .player-balance {
      width: 100%;
      // min-width: 8vw;
      // @media (max-height: 1000px) {
      //   min-width: 7vw;
      // }
      // @media (max-height: 800px) {
      //   min-width: 6vw;
      // }
      // @media (max-height: 600px) {
      //   min-width: 5vw;
      // }
    }

    .player-gift {
      top: 40%;
      //right: 0%;
      //left: unset;
      min-width: 1.4vw;
      min-height: 1.4vw;
      border-radius: 50px;

      &-fullscreen {
        right: -10%;
        left: unset;
      }

      &-image {
        width: 90%;
        padding: 7%;
        height: 84%;
        margin: 0;
      }
    }

    .block-button {
      top: 11%;
      //right: 8%;
      //left: unset;
      min-width: 1.1vw;
      min-height: 1.1vw;
      border-radius: 50px;

      &-fullscreen {
        right: 2%;
        left: unset;
      }

      &-image {
        width: 90%;
        padding: 7%;
        height: 84%;
        margin: 0;
      }
    }

    .played-cards {
      //  left: 40%;
      top: 25%;
      left: unset;
      width: 20%;
      min-width: 150px;
      max-width: 200px;
      margin-left: 45%;
      //  margin-right: 37%;
      z-index: 988;

      @media (min-width: 1200px) {
        top: 24%;
        margin-left: 44%;
      }

      @media (min-width: 992px) {
        top: 21%;
        margin-left: 46%;
      }

      @media (min-width: 768px) {
        top: 17%;
        margin-left: 48%;
      }

      @media screen and (orientation : landscape) and (min-width: 600px) {
        top: 17%;
        margin-left: 48%;
      }

        @media screen and (orientation : portrait) and (max-height: 800px) {
          top: 17%;
          margin-left: 45%;
        }

        @media screen and (orientation : portrait) and (max-height: 700px) {
          top: 16%;
          margin-left: 45%;
        }

        @media screen and (orientation : portrait) and (max-height: 600px) {
          top: 15%;
          margin-left: 45%;
        }

        @media (max-width: 582px) {
          margin-left: 40%;
        }
        //  border: 1px solid red;

        .card {
          width: 130px;
          height: 194px;
          margin-right: -58px;
          background-size: 130px 194px;

          &-0 {
            left: -20%;
          }

          &-1 {
            top: 40px;
            left: 31%;
          }

          &-2 {
            right: -14%;
            left: unset;
          }
          // @media (max-width: 1200px) {
          //   width: 120px;
          //   height: 179px;
          //   margin-right: -58px;
          //   background-size: 120px 179px;
          //   &-0 {
          //     left: -20%;
          //   }
          //   &-1 {
          //     top: 40px;
          //     //  left: 31%;
          //   }
          //   &-2 {
          //     right: -14%;
          //     left: unset;
          //   }
          // }

          @media (max-width: 1920px) {
            width: 105px;
            height: 156px;
            margin-right: -58px;
            background-size: 105px 156px;

            &-0 {
              left: -20%;
            }

            &-1 {
              top: 40px;
              //  left: 31%;
            }

            &-2 {
              right: -5%;
              left: unset;
            }
          }

          @media (max-width: 1500px) {
            width: 85px;
            height: 135px;
            background-size: 85px 135px;

            &-0 {
              left: -20%;
            }

            &-1 {
              top: 40px;
            }

            &-2 {
              right: -5%;
              left: unset;
            }
          }

          @media (max-width: 1367px) {
            width: 75px;
            height: 119px;
            background-size: 75px 119px;

            &-0 {
              left: -20%;
            }

            &-1 {
              top: 40px;
            }

            &-2 {
              right: -5%;
              left: unset;
            }
          }

          @media (max-width: 1100px) {
            width: 75px;
            height: 119px;
            background-size: 75px 119px;

            &-0 {
              left: -20%;
            }

            &-1 {
              top: 40px;
              //  left: 31%;
            }

            &-2 {
              right: -5%;
              left: unset;
            }
          }

          @media (max-width: 768px) {
            width: 95px;
            height: 142px;
            margin-right: -58px;
            background-size: 95px 142px;

            &-0 {
              left: -17%;
            }

            &-1 {
              top: 30px;
              //  left: 26%;
            }

            &-2 {
              right: -3%;
              left: unset;
            }
          }
        }

        .table-card-web {
          //  width: 84px;
          //  height: 125px;
          //width: 100px;
          //height: 149px;

          width: 75px; //
          height: 112px; //
          background-size: 75px 112px; //

          background-color: transparent;
          float: left;
          margin-right: -58px;
          border: none;
          transition: none !important;
          cursor: pointer;
          //background-size: 100px 149px;
          background-position: top center;
          background-repeat: no-repeat;
          // // left card
          // &.card-0 {
          //   top: 10px;
          //   left: -38px;
          //   position: absolute;
          //   //  z-index: 10;
          // }
          // // bottom card
          // &.card-1 {
          //   top: 30px;
          //   left: 40px;
          //   position: absolute;
          //   //  z-index: 30;
          // }
          // // right card
          // &.card-2 {
          //   top: 10px;
          //   left: 115px;
          //   position: absolute;
          //   //  z-index: 20;
          // }
          // // top card
          // &.card-3 {
          //   top: -10px;
          //   left: 40px;
          //   position: absolute;
          //   //  z-index: 20;
          // }
          //left card
          &.card-0 {
            top: 10px;
            left: -28px;
          }
          // bottom card
          &.card-1 {
            top: 30px;
            left: 40px;
          }
          // right card
          &.card-2 {
            top: 10px;
            left: 105px;
          }
          // top card
          &.card-3 {
            top: -10px;
            left: 40px;
          }

          @media (max-height: 800px) {
            width: 75px;
            height: 112px;
            background-size: 75px 112px;
            //left card
            &.card-0 {
              top: 10px;
              left: -28px;
            }
            // bottom card
            &.card-1 {
              top: 30px;
              left: 40px;
            }
            // right card
            &.card-2 {
              top: 10px;
              left: 105px;
            }
            // top card
            &.card-3 {
              top: -10px;
              left: 40px;
            }
          }

          @media (max-height: 700px) {
            //  width: 60px;
            //  height: 89px;
            //  background-size: 60px 89px;
            // left card
            &.card-0 {
              top: 10px;
              left: -18px;
            }
            // bottom card
            &.card-1 {
              top: 30px;
              left: 40px;
            }
            // right card
            &.card-2 {
              top: 10px;
              left: 95px;
            }
            // top card
            &.card-3 {
              top: -10px;
              left: 40px;
            }
          }

          @media (max-height: 600px) {
            width: 65px;
            height: 96px;
            background-size: 65px 96px;
            // left card
            &.card-0 {
              top: 10px;
              left: -13px;
            }
            // bottom card
            &.card-1 {
              top: 30px;
              left: 40px;
            }
            // right card
            &.card-2 {
              top: 10px;
              left: 90px;
            }
            // top card
            &.card-3 {
              top: -10px;
              left: 40px;
            }
          }

          @media (max-height: 450px) {
            width: 60px;
            height: 89px;
            background-size: 60px 89px;
          }
        }
        /*  .card-0 {
        left: -10%;
      }

      .card-1 {
        top: 40px;
        left: 31%;
      //  left: -1%;
      }

      .card-2 {
        right: 8%;
        left: unset;
      } */
      }

      .cards {
        left: 46%;
        bottom: 52%;
        top: unset;

        @media (max-width: 1200px) {
          bottom: 49%;
        }

        @media (max-width: 992px) {
          bottom: 48%;
        }

        @media (max-width: 768px) {
          bottom: 48%;
        }

        &-web {
          @media (max-width: 1200px) {
            bottom: 52%;
          }

          @media (max-width: 992px) {
            bottom: 51%;
          }

          @media (max-width: 768px) {
            bottom: 49%;
          }

          @media (max-height: 800px) {
            bottom: 55%;
          }

          @media (max-height: 700px) {
            bottom: 56%;
          }

          @media (max-height: 600px) {
            bottom: 57%;
          }

          @media (max-height: 600px) {
            bottom: 57%;
          }
        }

        &-even {
          left: 47%;
        }

        .blocked-overlay {
          height: 184px;
          width: 122px;
          //  min-height: 184px;
          //  min-width: 122px;
        }

        .hovered {
          .allowed {
            //  top: -34px!important;
            top: -26px;
            margin-top: 0;
          }
        }

        .hoveredNeighbour {
          .allowed {
            //  top: -24px!important;
            top: -19px;
            margin-top: 0;
          }
        }

        .card {
          //  min-height: 189px;
          //  min-width: 126px;
          width: 126px;
          height: 189px;
          background-color: transparent;
          float: left;
          margin-right: -58px;
          border: none;
          transition: 0.3s ease;
          cursor: pointer;
          position: absolute;
          transform-origin: top left;
          background-size: 126px 189px;
          background-position: top center;
          background-repeat: no-repeat;

          @media (max-width: 1200px) {
            width: 113px;
            height: 170px;
            background-size: 113px 170px;

            .blocked-overlay {
              height: 179px;
              width: 118px;
            }
          }

          @media (max-width: 1100px) {
            width: 101px;
            height: 151px;
            background-size: 101px 151px;

            .blocked-overlay {
              height: 146px;
              width: 97px;
            }
          }

          @media (max-width: 992px) {
            width: 88px;
            height: 132px;
            background-size: 88px 132px;

            .blocked-overlay {
              height: 127px;
              width: 84px;
            }
          }

          @media (max-width: 768px) {
            width: 82px;
            height: 123px;
            background-size: 82px 123px;

            .blocked-overlay {
              height: 118px;
              width: 78px;
            }
          }

          @media (max-height: 800px) {
            width: 101px;
            height: 151px;
            background-size: 101px 151px;

            .blocked-overlay {
              height: 146px;
              width: 97px;
            }
          }

          @media (max-height: 700px) {
            width: 88px;
            height: 132px;
            background-size: 88px 132px;

            .blocked-overlay {
              height: 127px;
              width: 84px;
            }
          }

          @media (max-height: 600px) {
            width: 82px;
            height: 123px;
            background-size: 82px 123px;

            .blocked-overlay {
              height: 118px;
              width: 78px;
            }
          }

          @media (max-height: 500px) {
            width: 76px;
            height: 113px;
            background-size: 76px 113px;

            .blocked-overlay {
              height: 108px;
              width: 72px;
            }
          }


          &-0 {
            left: -225px;
            top: 55px;
            transform: rotate(-19deg);
          }

          &-1 {
            left: -175px;
            top: 41px;
            transform: rotate(-14.41deg);
          }

          &-2 {
            left: -125px;
            top: 27px;
            transform: rotate(-10.46deg);
          }

          &-3 {
            left: -75px;
            top: 16px;
            transform: rotate(-7.23deg);
          }

          &-4 {
            left: -25px;
            top: 8px;
            transform: rotate(-3.89deg);
          }

          &-5 {
            left: 25px;
            top: 5px;
            transform: rotate(-0deg);
          }

          &-6 {
            left: 75px;
            top: 5px;
            transform: rotate(3.89deg);
          }

          &-7 {
            left: 123px;
            top: 8px;
            transform: rotate(7.23deg);
          }

          &-8 {
            left: 170px;
            top: 15px;
            transform: rotate(10.46deg);
          }

          &-9 {
            left: 220px;
            top: 24px;
            transform: rotate(14deg);
          }
        }
      }

      .four-player-table {

        .cards {
          left: 46%;
          bottom: 50%;
          top: unset;

          @media (max-width: 1200px) {
            bottom: 51%;
          }

          @media (max-width: 992px) {
            bottom: 49%;
          }

          @media (max-width: 768px) {
            bottom: 50%;
          }

          &-even {
            left: 47%;
          }

          .card {
            width: 126px;
            height: 189px;
            background-color: transparent;
            float: left;
            margin-right: -58px;
            border: none;
            transition: 0.3s ease;
            cursor: pointer;
            position: absolute;
            transform-origin: top left;
            background-size: 126px 189px;
            background-position: top center;
            background-repeat: no-repeat;

            @media (max-width: 1200px) {
              width: 113px;
              height: 170px;
              background-size: 113px 170px;

              .blocked-overlay {
                height: 179px;
                width: 118px;
              }
            }

            @media (max-width: 1100px) {
              width: 101px;
              height: 151px;
              background-size: 101px 151px;

              .blocked-overlay {
                height: 146px;
                width: 97px;
              }
            }

            @media (max-width: 992px) {
              width: 88px;
              height: 132px;
              background-size: 88px 132px;

              .blocked-overlay {
                height: 127px;
                width: 84px;
              }
            }

            @media (max-width: 768px) {
              width: 82px;
              height: 123px;
              background-size: 82px 123px;

              .blocked-overlay {
                height: 118px;
                width: 78px;
              }
            }

            @media (max-height: 1100px) {
              width: 101px;
              height: 151px;
              background-size: 101px 151px;

              .blocked-overlay {
                height: 146px;
                width: 97px;
              }
            }

            @media (max-height: 900px) {
              width: 88px;
              height: 132px;
              background-size: 88px 132px;

              .blocked-overlay {
                height: 127px;
                width: 84px;
              }
            }

            @media (max-height: 700px) {
              width: 82px;
              height: 123px;
              background-size: 82px 123px;

              .blocked-overlay {
                height: 118px;
                width: 78px;
              }
            }

            @media (max-height: 500px) {
              width: 76px;
              height: 113px;
              background-size: 76px 113px;

              .blocked-overlay {
                height: 108px;
                width: 72px;
              }
            }
          }

          .blocked {
            margin-top: 0px;

            &-overlay {
              //  height: 110px;
              //  width: 76px;

              width: 122px;
              height: 185px;
              background: rgba(0, 0, 0, 0.20),
            }
          }



          .hovered {
            .allowed {
              //  top: -34px!important;
              top: -26px;
            }
          }

          .hoveredNeighbour {
            .allowed {
              //  top: -24px!important;
              top: -19px;
            }
          }
        }

        .played-cards {
          //  left: 40%;
          top: 28%;
          left: unset;
          width: 20%;
          min-width: 150px;
          max-width: 200px;
          margin-left: 45%;
          //  margin-right: 37%;
          z-index: 988;

          @media (max-width: 1760px) {
            top: 28%;
            margin-left: 44%;
          }

          @media (max-width: 1500px) {
            top: 29%;
            margin-left: 44%;
          }

          @media (max-width: 1200px) {
            top: 27%;
            margin-left: 44%;
          }

          @media (max-width: 992px) {
            top: 28%;
            margin-left: 42%;
          }

          @media (max-width: 768px) {
            top: 30%;
            margin-left: 40%;
          }

          @media (max-height: 800px) {
            top: 28.2%;
            margin-left: 45%;
          }

          @media (max-height: 700px) {
            top: 28.6%;
            margin-left: 45%;
          }

          @media (max-height: 600px) {
            top: 29%;
            margin-left: 45%;
          }

          @media (max-width: 582px) {
            margin-left: 40%;
          }
          //  border: 1px solid red;

          .card {
            width: 130px;
            height: 194px;
            margin-right: -58px;
            background-size: 130px 194px;

            &-0 {
              left: -20%;
            }

            &-1 {
              top: 40px;
              left: 31%;
            }

            &-2 {
              right: -14%;
              left: unset;
            }

            @media (max-height: 1200px) {
              width: 75px;
              height: 112px;
              background-size: 75px 112px;

              &.card-0 {
                top: 10px;
                left: -28px;
              }
              // bottom card
              &.card-1 {
                top: 30px;
                left: 40px;
              }
              // right card
              &.card-2 {
                top: 10px;
                left: 105px;
              }
              // top card
              &.card-3 {
                top: -10px;
                left: 40px;
              }
            }

            @media (max-height: 1000px) {
              //  width: 60px;
              //  height: 89px;
              //  background-size: 60px 89px;

              width: 75px;
              height: 112px;
              background-size: 75px 112px;
              // left card
              &.card-0 {
                top: 10px;
                left: -18px;
              }
              // bottom card
              &.card-1 {
                top: 30px;
                left: 40px;
              }
              // right card
              &.card-2 {
                top: 10px;
                left: 95px;
              }
              // top card
              &.card-3 {
                top: -10px;
                left: 40px;
              }
            }

            @media (max-height: 700px) {
              width: 65px;
              height: 96px;
              background-size: 65px 96px;
              // left card
              &.card-0 {
                top: 10px;
                left: -13px;
              }
              // bottom card
              &.card-1 {
                top: 30px;
                left: 40px;
              }
              // right card
              &.card-2 {
                top: 10px;
                left: 90px;
              }
              // top card
              &.card-3 {
                top: -10px;
                left: 40px;
              }
            }

            @media (max-height: 450px) {
              width: 60px;
              height: 89px;
              background-size: 60px 89px;
            }
            //768
          }

          .table-card-web {
            //  width: 84px;
            //  height: 125px;

            width: 75px; //
            height: 112px; //
            background-size: 75px 112px; //
            // width: 100px;
            // height: 149px;
            // background-size: 100px 149px;


            background-color: transparent;
            float: left;
            margin-right: -58px;
            border: none;
            transition: none !important;
            cursor: pointer;
            background-position: top center;
            background-repeat: no-repeat;
            // left card
            &.card-0 {
              top: 10px;
              left: -38px;
              position: absolute;
              //  z-index: 10;
            }
            // bottom card
            &.card-1 {
              top: 30px;
              left: 40px;
              position: absolute;
              //  z-index: 30;
            }
            // right card
            &.card-2 {
              top: 10px;
              left: 115px;
              position: absolute;
              //  z-index: 20;
            }
            // top card
            &.card-3 {
              top: -10px;
              left: 40px;
              position: absolute;
              //  z-index: 20;
            }

            @media (max-height: 1200px) {
              width: 75px;
              height: 112px;
              background-size: 75px 112px;

              &.card-0 {
                top: 10px;
                left: -28px;
              }
              // bottom card
              &.card-1 {
                top: 30px;
                left: 40px;
              }
              // right card
              &.card-2 {
                top: 10px;
                left: 105px;
              }
              // top card
              &.card-3 {
                top: -10px;
                left: 40px;
              }
            }

            @media (max-height: 1000px) {
              //  width: 60px;
              //  height: 89px;
              //  background-size: 60px 89px;

              width: 75px;
              height: 112px;
              background-size: 75px 112px;
              // left card
              &.card-0 {
                top: 10px;
                left: -18px;
              }
              // bottom card
              &.card-1 {
                top: 30px;
                left: 40px;
              }
              // right card
              &.card-2 {
                top: 10px;
                left: 95px;
              }
              // top card
              &.card-3 {
                top: -10px;
                left: 40px;
              }
            }

            @media (max-height: 700px) {
              width: 65px;
              height: 96px;
              background-size: 65px 96px;
              // left card
              &.card-0 {
                top: 10px;
                left: -13px;
              }
              // bottom card
              &.card-1 {
                top: 30px;
                left: 40px;
              }
              // right card
              &.card-2 {
                top: 10px;
                left: 90px;
              }
              // top card
              &.card-3 {
                top: -10px;
                left: 40px;
              }
            }

            @media (max-height: 450px) {
              width: 60px;
              height: 89px;
              background-size: 60px 89px;
            }
          }
        }
        /*  .cards {
        top: 345px;
      //  left: 452px;
        left: 45%;

        &-odd {
        //  left: 433px;
          left: 44%;
        }

        &-even {
        //  left: 452px;
          left: 45%;
        }

        .blocked {
          margin-top: 0px;

          &-overlay {
            position: relative;
          //  width: 100%;
          //  height: 100%;

            transition: .3s ease;

            height: 110px;
            width: 76px;
            margin-top: 0;
            margin-left: 2px;
            margin-right: 5px;
            padding-right: 10px;

            border-radius: 5px;

            cursor: default;

            background: rgba(0, 0, 0, 0.20),
          }
        }

        .hovered {
          .allowed {
          //  top: -34px!important;
            top: unset;
          }

          .card {
            width: 104px;
            height: 138px;
            background-size: 104px 138px;
            margin-top: -24px;
          }

          .blocked-overlay {
          //  height: 134px!important;
          //  width: 100px!important;
            height: unset;
            width: unset;
          }
        }

        .hoveredNeighbour {
          .allowed {
          //  top: -24px!important;
            top: unset;
          }

          .card {
          //  width: 94px!important;
          //  height: 128px!important;
          //  background-size: 94px 128px!important;
            width: unset;
            height: unset;
            background-size: unset;
            margin-top: -12px;
          }

          .blocked-overlay {
          //  height: 124px!important;
          //  width: 90px!important;
            height: unset;
            width: unset;
          }
        }
      }  */
        /*   .cards .card {
        width: 80px;
        height: 114px;
        background-size: 80px 114px;
        transition: .3s ease;
      }

      .played-cards .card {
        width: 75px;
        height: 110px;
        background-size: 75px 110px;
      }

      .played-cards .card-0 {
        top: 10px;
        left: -20px;
      }

      .played-cards .card-1 {
        top: 35px;
        left: 45px;
      }

      .played-cards .card-2 {
        top: 10px;
        left: 100px;
      }

      .played-cards .card-3 {
        top: -10px;
        left: 30px;
      }

     .played-cards {
        position: absolute;
        top: 190px;
        left: 405px;
      }

      .player-image-timer-wrapper {
        display: inline-block;
        position: relative;
        width: 104px;
        height: 104px;
        text-align: center;
      }

      .player-avatar-wrapper {
        width: 105px;
        height: 105px;
        border-radius: 70px;
        display: inline-block;
        background-color: transparent;
        overflow: hidden;
        padding: 4px;
      }

      .player-avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: transparent;
        overflow: hidden;
        border: 5px solid transparent;
      }

      .player-avatar img {
        width: 95px;
        height: 95px;
      }

      .player-firstperson {
        top: 477px;
        left: 430px;
      } */
      }

      .last-round {
        // right: 36%;
        // bottom: 25px;
        // max-width: 13%;
        // top: unset;
        // @media (max-width: 992px) {
        //   right: 30%;
        // }

        &-button {
          border-radius: 50px;
          width: 120px;
          height: auto;
          padding: 5px 10px;

          @media (max-width: 992px) {
            font-size: var(--defaultFontSize);
          }

          @media (max-width: 768px) {
            font-size: var(--mSmallFontSize);
          }

          &-ru {
            line-height: 14px;
          }
        }
      }

      .hurry-icon-old {
        position: fixed;
        bottom: 520px;
        right: 20px;
        background: url('../../../images/Game/Hurry up clock.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 50px;
        height: 51px;
        pointer-events: all;

        &.disabled {
          filter: grayscale(1) opacity(50%);
          //  pointer-events: none;
        }
      }

      @media (max-height: 800px) {
        .hurry-icon-old {
          bottom: 385px;
        }
      }

      .quit-round {
        // left: 36%;
        // bottom: 25px;
        // max-width: 13%;
        // top: unset;
        // @media (max-width: 992px) {
        //   left: 42%;
        // }

        &-button {
          width: 120px;
          border-radius: 50px;

          @media (max-width: 992px) {
            font-size: var(--defaultFontSize);
          }

          @media (max-width: 768px) {
            font-size: var(--mSmallFontSize);
          }
        }
      }

      .previous-round {

        &-image {
          width: 50px;
          height: 50px;
        }
      }

      .score-table {
        bottom: 25px;
        top: unset;
        max-width: 25%;
        width: 25%;
        white-space: nowrap;

        .score-table-scroll-area {
          height: 220px;
        }
      }

      .game-stats {
        bottom: 360px;
        top: unset;
        max-width: 25%;
        z-index: 2;
      }

      .score-table-toggle-open {
        bottom: 392px;
        top: unset;

        &-tournament {
          bottom: 429px;
        }
      }

      @media (max-width: 1350px) {
        .score-table {
          max-width: 24%;
          width: 24%;
        }
      }

      @media (max-width: 1200px) {
        .score-table {
          bottom: 23px;
          max-width: 25%;
          width: 25%;

          .score-table-scroll-area {
            height: 115px;
          }
        }

        .game-stats {
          bottom: 237px;
        }

        .score-table-toggle-open {
          bottom: 287px;
          top: unset;
        }
      }

      @media (max-height: 800px) {
        .score-table {
          .score-table-scroll-area {
            height: 115px;
          }
        }

        .game-stats {
          bottom: 237px;
        }

        .score-table-toggle-open {
          bottom: 320px !important;
          top: unset;

          &-tournament {
            bottom: 306px;
          }
        }
      }

      .score-table-toggle-closed {
        bottom: 25px;
        top: unset;
      }

      .chat {
        max-width: 35%;
        width: 420px;

        &-message-user {
          font-size: var(--largeFontSize);
        }

        &-message-game {
          font-size: var(--largeFontSize);
        }

        &-open {
          height: 30%;
          max-height: 270px;
        }

        @media (max-width: 1350px) {
          width: 350px;

          &-open {
            height: 30%;
            max-height: 230px;
          }
        }

        @media (max-width: 1200px) {
          width: 320px;

          &-message-user {
            font-size: var(--defaultFontSize);
          }

          &-message-game {
            font-size: var(--defaultFontSize);
          }

          &-open {
            height: 30%;
            max-height: 210px;
          }
        }

        @media (max-width: 992px) {
          width: 310px;

          &-message-user {
            font-size: var(--mSmallFontSize);
          }

          &-message-game {
            font-size: var(--mSmallFontSize);
          }

          &-open {
            height: 30%;
            max-height: 200px;
          }
        }

        @media (max-width: 768px) {
          width: 280px;

          &-message-user {
            font-size: var(--xSmallFontSize);
            // font-size: 9px;
          }

          &-message-game {
            font-size: var(--xSmallFontSize);
            // font-size: 9px;
          }
        }

        &-bar {
          max-width: 35%;
          width: 420px;

          @media (max-width: 1350px) {
            width: 350px;
          }

          @media (max-width: 1200px) {
            width: 320px;
          }

          @media (max-width: 992px) {
            width: 310px;
          }

          @media (max-width: 768px) {
            width: 280px;
          }

          &-left {
            padding-right: 0;
            //  &-button {
            //    height: auto;
            //    max-height: 30px;
            //    max-width: 33%;
            //  }
          }

          &-right {
            padding-left: 0;
          }
        }

        &-xLarge {
          max-height: 300px;
          //height: 40%;
          // & .chat-header {
          //   height: 33%;
          // }

          & .chat-body {
            height: 50%;
          }
        }
      }

      .emotions {
        max-width: 35%;
        width: 420px;

        &-open {
          // height: 30%;
          // max-height: 300px;
        }

        @media (max-width: 1350px) {
          width: 350px;

          &-open {
            // height: 30%;
            // max-height: 270px;
          }
        }

        @media (max-width: 1200px) {
          width: 340px;

          &-open {
            // height: 30%;
            // max-height: 250px;
          }
        }

        @media (max-width: 992px) {
          width: 320px;

          &-open {
            // height: 30%;
            // max-height: 240px;
          }
        }

        @media (max-width: 768px) {
          width: 280px;
        }

        &-bar {
          max-width: 35%;
          width: 420px;

          @media (max-width: 1350px) {
            width: 350px;
          }

          @media (max-width: 1200px) {
            width: 340px;
          }

          @media (max-width: 992px) {
            width: 320px;
          }

          @media (max-width: 768px) {
            width: 280px;
          }

          &-left {
            padding-right: 0;

            &-button {
              height: auto;
              max-height: 30px;
              max-width: 33%;
            }
          }

          &-right {
            padding-left: 0;
          }
        }
      }

      .gift-modal {
        width: 90% !important;
        max-width: 90% !important;

        &-body {

          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 0px;
            border-left: 3px solid #2c5955;
            border-right: 3px solid #2c5955;
          }

          &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 0px;
            border-left: 4px solid #2c5955;
            border-right: 4px solid #2c5955;
          }

          &-wrapper {
            padding: 0;
            height: 100%;
            // top: 1px;
          }

          &-scroll {

            &-area {
              height: 100%;
              max-height: 75vh;
              width: 100%;
              margin-right: 2px;

              & .scrollbar {
                //  height: 25px!important;
              }
            }
          }
        }
      }

      .dealingNewCardsText {
        top: 30%;
      }
    }


    &.app-wrapper-facebook {
      width: 99% !important;
      height: 99%;
      min-width: 65vw;
      min-height: 710px;
      padding-right: 45px;
      max-width: 1800px;
      margin-left: auto;
      margin-right: auto;

      & .old-zole-game-section-campaign {

        & .score-table {
          bottom: 75px;
        }

        & .game-stats {
          bottom: 380px;
        }

        & .last-round {
          bottom: 80px;
        }

        & .quit-round {
          bottom: 80px;
        }
      }
    }

    .user-settings-game {
      &-button {
        position: absolute;
        top: 55px;
        right: 0px;
        width: 33px;
        -webkit-filter: invert(100%);
        filter: invert(100%);
        cursor: pointer;
        z-index: 999;
      }
    }

    .fullscreen-button-game {
      //  position: absolute;
      //  top: 95px;
      //  top: 45px;

      position: relative;
      display: block;
      right: -5px;
      z-index: 950;
    }

    .dealingNewCardsText {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 220px;
      font-size: var(--xLargeFontSize);
      // font-size: 22px;
      font-weight: 500;
      color: #FFF;
      z-index: 1;

      &-mobile {
        top: calc(100vh / 2);
      }
    }

    .game-background {
      width: 980px;
      height: 700px;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-image: url('../../../images/Game/zole_fons_3.jpg');
      background-size: cover,auto;
      background-position: center center;
      z-index: 0;
    }
    //  .tabletop{
    //    @include background-2x( '../../images/table-o', 'png', 637px, 399px, center center, no-repeat );
    //    width: 637px;
    //    height: 399px;
    //    position: absolute;
    //    top:200px;
    //    left: 179px;
    //    z-index: -100;
    //  }

    .logo {
      background-image: url('../../../images/Menu/zole_logo_2.png');
      width: 284px;
      height: 57px;
      float: right;
      background-size: cover, auto;
      background-position: top center;

      &-wrapper {
        position: absolute;
        top: 25px;
        right: 45px;
        //  width: 100%;
        width: 60%;
      }
    }

    .logo-wrapper.loading {
      right: 1px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: inline-block;
      height: 60px;
      margin: auto;

      @media screen and (max-height: 800px) {
        margin: 0 auto;
        top: 200px;
      }

      @media screen and (max-height: 680px) {
        margin: 0 auto;
        top: 50px;
      }

      .logo.loading {
        float: none;
        margin: auto;
        height: 70px;
        width: 350px;
        background-image: url('../../../images/landing-view/zole_logo.svg');
      }
    }

    .loadingText {
      color: #FFF;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 800;
      font-size: var(--mLargeFontSize);
      // font-size: 18px;
      line-height: 27px;
      text-align: center;
      // text-transform: uppercase;
      padding-top: 15px;
    }

    .loadingScreen {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: inline-block;
      margin: auto;
      height: 30px;
      padding-top: 150px;

      @media screen and (max-height: 800px) {
        margin: 0 auto;
        top: 200px;
      }

      @media screen and (max-height: 680px) {
        margin: 0 auto;
        top: 50px;
      }

      .progress {
        background-color: #2E5A56;
        border-radius: 15px;

        .progress-bar {
          background-color: #FFD34F !important;
        }
      }
    }

    .game-logo {
      background-image: url('../../../images/Menu/zole_logo_2.png');
      width: 207px;
      height: 42px;
      display: block;
      background-size: cover,auto;
      background-position: top center;
      margin-left: auto;
      margin-right: 15px;

      &-wrapper {
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }

    .game-clock {
      position: fixed;
      bottom: 3px;
      right: 3px;
      font-size: var(--xSmallFontSize);
      // font-size: 7px;
      //  width: 100%;

      background-size: cover,auto;
      background-position: top center;
      margin-left: auto;
      z-index: 1000;
      color: #FFF;
    }
    //
    // Player
    //
    .player {
      position: fixed;
      margin-top: 5px;
      text-align: center;

      &-image-timer-wrapper {
        display: inline-block;
        position: relative;
        width: 114px;
        height: 114px;
        text-align: center;
      }

      &-status {
        display: inline-block;
        height: auto;
        min-height: 1.2vh;
        min-width: 3.7vw;
        color: #fff;
        border-radius: 8px;
        background-color: #C54D4D;
        border: 1px solid #FFFFFF;
        font-style: normal;
        font-weight: bold;
        font-size: var(--smallFontSize);
        // font-size: 1vmin;
        line-height: 17px;
        padding-left: 4px;
        padding-right: 4px;
        vertical-align: middle;
        cursor: default;

        &-wrapper {
          position: absolute;
          top: -22px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          // min-width: 8vw;
          z-index: 500;
        }
      }

      &-turn {
        text-align: center;
        padding-top: 5px;
        height: 30px;

        &-image {
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 5px;
        }

        &-text {
          display: inline-block;
          color: #FFD34E;
          font-size: var(--xLargeFontSize);
        }
      }

      &-avatar {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        background-color: transparent;
        overflow: hidden;
        border: 5px solid transparent;

        &-active.player-avatar-lielais {
          border: 5px solid #C54D4D;
        }

        &-active.player-avatar-mazais {
          border: 5px solid #FF8C00;
        }

        &-wrapper {
          width: 115px;
          height: 115px;
          border-radius: 70px;
          display: inline-block;
          background-color: transparent;
          overflow: hidden;
          padding: 4px;
        }

        img {
          width: 105px;
          height: 105px;
        }
      }

      &-name {
        display: inline-block;
        font-size: var(--largeFontSize);
        // font-size: 15px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-top: 0px;
        margin-left: 5px;
        cursor: default;

        &-wrapper {
          top: -25px;
          position: relative;
          height: 29px;
        }
      }

      &-additional-info {
        display: inline-block;
        color: #fff;
        text-align: center;
        margin-top: 0px;
        margin-left: 5px;

        &-img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          display: inline-block;
          cursor: pointer;
        }
      }

      &-balance {
        color: #FFD34E;
        text-align: center;
        //margin-left: 7px;
        left: 0;
        right: 0;
        margin: auto;
        top: -30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &-coin {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          display: inline-block;
        }

        &-text {
          font-size: var(--largeFontSize);
          // font-size: 15px;
          font-weight: bold;
          display: inline-block;

          &-orange {
            color: #F29100;
          }

          &-red {
            padding-left: 3px;
            padding-right: 3px;
            background: #FFD34E;
            color: #C44C4D;
            border-radius: 7px;
            font-weight: bold;
            line-height: 18px;
          }
        }

        &-popover {
          width: 385px;
          height: 225.96px;
          background-color: #2C5956;


          & .popover {
            width: 385px;
            height: 225.96px;
            max-width: 385px;
            left: 105px !important;
            border-radius: 18px !important;
          }

          & .popover .arrow {
            left: 18px !important;
          }

          & .popover-inner {
            width: 100%;
            height: 100%;
          }

          &-body {
            width: 100%;
            height: 100%;
            display: flex;
            color: #fff;
            flex-direction: column;
            padding: 20px;

            &-header {
              width: 100%;
              height: 30%;
              font-size: var(--mSmallFontSize);
              font-weight: 600;
            }

            &-main {
              width: 100%;
              height: 40%;
              font-size: var(--headerFontSize);
              font-weight: 400;
            }

            &-footer {
              width: 100%;
              height: 30%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-end;

              &-purchase-button {
                width: auto;
                height: 30px;
                max-width: auto;
                /* left: 160px; */
                /* top: 309px; */
                background: #F29100;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                box-shadow: 4px 4px 0px #380000;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
              }

              &-reset-button {
                width: auto;
                height: 30px;
                max-width: auto;
                background: #C44C4D;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                box-shadow: 4px 4px 0px #380000;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }

      &-type {
        text-align: center;
        color: #fff;
        font-size: var(--largeFontSize);
        margin-left: 15px;

        &-lielais {
          background: #C54D4D;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 8px;
          display: inline-block;
          color: #FFFFFF;
          height: auto;
          font-weight: bold;
          font-size: var(--defaultFontSize);
          // font-size: 13px;
          line-height: 16px;
          padding: 2px 10px;
          cursor: default;
          min-width: 60%;
          margin-left: auto;
          margin-right: auto;
          min-height: 1.6vh;
        }

        &-mazais {
          background: #FF8C00;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 8px;
          display: inline-block;
          color: #FFFFFF;
          height: auto;
          font-weight: bold;
          font-size: var(--defaultFontSize);
          // font-size: 13px;
          line-height: 16px;
          padding: 2px 9px;
          cursor: default;
          min-width: 60%;
          margin-left: auto;
          margin-right: auto;
          min-height: 1.6vh;
        }

        &-none {
          height: 26px;
        }

        &-text {
          text-align: center;
          color: #fff;
          font-size: var(--largeFontSize);
          margin-left: 15px;
        }

        &-image {
          width: 100px;
          z-index: 50;
          display: inline-block;
        }

        &-wrapper {
          position: relative;
          width: 100%;
          z-index: 50;
          top: -28px;
          text-align: center;
        }
      }

      &-current-hand {
        z-index: 99;
        border-radius: 7px;
        padding: 2px;
        padding-left: 5px;
        color: #FFFFFF;
        text-align: center;
        width: 44px;
        height: 28px;
        min-height: 1.3vh;
        min-width: 1.7vw;
        position: absolute;
        left: -7px;
        top: 64px;
        cursor: default;
        background-color: #2c5955;
        border: 1px solid #fff;
        -webkit-box-shadow: 2px 2px 0px 0px #380000;
        -moz-box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 2px 2px 0px 0px #380000;
        text-align: center;

        &-image {
          display: inline-block;
          margin-bottom: 4px;
          width: 20px;
        }

        &-text {
          display: inline-block;
        }
      }

      &-level {
        /*  border-radius:15px;
      padding: 3px;
      color: #FFD34E;
      text-align: center;
      width: 32px;
      height: 32px;
      position: absolute;
      top:95px;
      left: 0px;
      background: #2c5955;
      border: 1px solid #fff; */

        display: inline-block;
        background-color: #c44c4c;
        border-radius: 50%;
        width: 19px;
        height: 19px;
        border: 1px solid #fff;
        color: #fff;
        font-size: var(--defaultFontSize);
        text-align: center;
        margin-left: 2px;

        &-text {
          margin: auto;
        }
      }

      &-gift {
        z-index: 50;
        border-radius: 50%;
        padding: 4px;
        padding-top: 2px;
        padding-left: 6px;
        color: #FFD34E;
        text-align: center;
        width: 36px;
        height: 36px;
        position: absolute;
        // left: 84px;
        top: 56px;
        right: -10%;
        left: unset;
        background-color: #2c5955;
        border: 1px solid #fff;
        -webkit-box-shadow: 2px 2px 0px 0px #380000;
        -moz-box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 2px 2px 0px 0px #380000;
        text-align: center;

        &:hover {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }

        &-image {
          width: 22px;
          height: 22px;
        }
      }

      &-level-progress {
        position: absolute;
        top: 50px;
        left: -130px;

        .level {
          color: $yellow;
        }

        .level-bar {
          width: 100px;
          height: 16px;
          background: $white;
          border-radius: 3px;
          overflow: hidden;

          div {
            background-color: #73bb73;
            height: 100%
          }
        }
      }

      &-gift-added {
        z-index: 99;
        pointer-events: all;

        img {
          max-height: 80px;
          max-width: 70px;
        }

        &-new {
          pointer-events: all;

          img {
            max-height: 80px;
            max-width: 70px;

            @media screen and (orientation : portrait) and (max-width: 1280px) {
              max-width: 40px;
              max-height: 40px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              max-width: 40px;
              max-height: 40px;
            }
          }
        }
      }

      &-emotion-image {
        &-new {

          @media screen and (orientation : portrait) and (max-width: 1280px) {
            max-width: 40px;
            max-height: 40px;
          }

          @media screen and (orientation : landscape) and (max-width: 1280px) {
            max-width: 40px;
            max-height: 40px;
          }
        }
      }


      &-left {
        top: 120px;
        left: 82px;

        .timer {
          position: absolute;
          z-index: -100;
          //  padding: 4px;
        }

        &-gift {
          position: absolute;
          top: 47px;
          right: -85px;


          &-new {
            position: absolute;
            top: 47px;
            right: -85px;

            @media screen and (orientation : portrait) {
              top: -20px;
              left: 0px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              top: 0px;
              left: -25px;
            }
          }
        }

        &-emotion {
          position: absolute;
          top: -20px;
          right: -70px;

          &-new {
            position: absolute;
            top: -20px;
            right: -70px;

            @media screen and (orientation : portrait) {
              top: -20px;
              right: 0px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              top: 0px;
              right: -25px;
            }
          }
        }
      }

      &-top {
        top: 22px;
        left: 50%;
        transform: translateX(-50%);

        .timer {
          position: absolute;
          z-index: -100;
          //  padding: 4px;
        }

        &-gift {
          position: absolute;
          top: 0px;
          left: -67px;

          &-new {
            position: absolute;
            top: 25px;
            left: -77px;

            @media screen and (orientation : portrait) {
              top: -20px;
              left: 0px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              top: 6px;
              left: -25px;
            }
          }
        }

        &-emotion {
          position: absolute;
          top: 30px;
          right: -70px;

          &-new {
            position: absolute;
            top: 20px;
            right: -70px;

            @media screen and (orientation : portrait) {
              top: -20px;
              right: 0px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              top: 6px;
              right: -25px;
            }
          }
        }
      }


      &-right {
        top: 120px;
        right: 82px;

        .timer {
          position: absolute;
          z-index: -100;
          //  padding: 4px;
        }

        &-gift {
          position: absolute;
          top: 47px;
          left: -85px;

          &-new {
            position: absolute;
            top: 47px;
            left: -85px;

            @media screen and (orientation : portrait) {
              top: -20px;
              left: 0px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              top: 0px;
              left: -25px;
            }
          }
        }

        &-emotion {
          position: absolute;
          top: -20px;
          left: -60px;

          &-new {
            position: absolute;
            top: -20px;
            left: -60px;

            @media screen and (orientation : portrait) {
              left: unset;
              top: -20px;
              right: 0px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              left: unset;
              top: 0px;
              right: -25px;
            }
          }
        }
      }

      &-firstperson {
        top: 445px;
        left: 50%;
        transform: translateX(-50%);

        .timer {
          position: absolute;
          z-index: -100;
          //  padding: 4px;
        }

        &-gift {
          position: absolute;
          top: 25px;
          right: 120px;

          &-new {
            position: absolute;
            bottom: 40px;
            left: -68px;

            @media screen and (orientation : portrait) {
              right: unset;
              bottom: unset;
              top: 35px;
              left: -38px;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              left: unset;
              bottom: unset;
              top: -37px;
              right: 38px;
            }
          }
        }

        &-emotion {
          position: absolute;
          top: 61px;
          right: -81px;

          &-new {
            // bottom: 114px;
            // right: 30px !important;
            position: absolute;
            right: -90px;
            top: unset;
            bottom: 50px;

            @media screen and (orientation : portrait) {
              top: 35px;
              right: -38px;
              bottom: unset;
              z-index: 10;
            }

            @media screen and (orientation : landscape) and (max-width: 1280px) {
              top: -20px;
              right: -24px;
            }
          }
        }
      }

      &-sittingOut {
        top: 10px;
        left: 210px;

        & .player-status-wrapper {
        }

        & .player-image-timer-wrapper {
          width: 77px;
          height: 77px;
        }

        & .player-avatar {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          background-color: transparent;
          overflow: hidden;
          border: 2px solid transparent;

          &-wrapper {
            width: 77px;
            height: 77px;
            border-radius: 70px;
            display: inline-block;
            background-color: transparent;
            overflow: hidden;
            padding: 2px;
          }

          img {
            width: 73px;
            height: 73px;
          }
        }

        & .block-button {
          width: 25px;
          height: 25px;
          top: 0;
          left: 60px;
        }

        & .player-gift {
          width: 30px;
          height: 30px;
          top: 30px;
          left: 65px;

          & img {
            width: 16px;
            height: 16px;
          }
        }

        .timer {
          position: absolute;
          z-index: -100;
          //  padding: 4px;
        }

        &-gift {
          position: absolute;
          top: 20px;
          right: 70px;

          & img {
            max-height: 60px;
            max-width: 52px;
          }

          &-new {
            position: absolute;
            top: 20px;
            right: 70px;

            & img {
              max-height: 60px;
              max-width: 52px;
            }
          }
        }

        &-emotion {
          position: absolute;
          top: 30px;
          right: -45px;

          & img {
            max-height: 45px;
            max-width: 38px;
          }

          &-new {
            position: absolute;
            top: 30px;
            right: -45px;

            & img {
              max-height: 45px;
              max-width: 38px;
            }
          }
        }
      }

      &-receivedGift {
        &-image {
          width: auto;
          //height: 70px;
        }

        &-tooltip {
          color: #fff;
          background-color: #2c5955;

          &-from {
            text-align: center;
            font-size: var(--largeFontSize);
          }

          &-comment {
            font-size: var(--defaultFontSize);
            text-align: justify;
          }
        }
      }
    }
    //
    // Score table
    //
    .game-stats {
      // width: 220px;
      width: 330px;
      position: absolute;
      top: 460px;
      right: 25px;
      border: 1px solid #fff;
      color: #fff;
      font-size: var(--defaultFontSize);
      text-align: center;
      z-index: 2;

      &-timer {
        //  font-family: 'DroidSansMono';
        //  font-size: 14px;
        // font-weight: 600;

        &-tournament {
          display: inline-block;
        }
      }

      &-table {
        width: 100%;

        &-col {
          width: 25%;
        }
      }
    }

    .score-table {
      width: 320px;
      position: absolute;
      top: 520px;
      right: 25px;
      border: 1px solid #fff;
      color: #fff;
      font-size: var(--defaultFontSize);

      &-hidden {
        height: 0;
        overflow: hidden;
        border: 0;
      }

      &-fourPlayer {
        width: 100%;

        &-scroll-content {
          width: 100%;
        }
        /*  & .score-table {
      &-header {
        width: 13%;
      }

      &-id-header {
        width: 13%;
      }

      &-pules-header {
        width: 13%;
      }

      &-id-col {
        width: 13%;
        &-div {
          width: 35px;
        }
      }

      &-pules-col {
        width: 13%;
        max-width: 38px;
        min-width: 38px;
      }

      &-col {
        width: 13%;
      }

      &-points {

        &-col{
          width: 13%;

          &-id {
            min-width: 37px;
            max-width: 37px;
            width: 37px;
          }

          &-pules {
            min-width: 53px;
            max-width: 53px;
            width: 53px;
          }
        }
      }

      &-sum {

        &-col{
          width: 23%;

          &-id {
            min-width: 37px;
            max-width: 37px;
            width: 37px;
          }

          &-pules {
            min-width: 53px;
            max-width: 53px;
            width: 53px;
          }
        }
      }
    }  */
      }

      &-header {
        width: 23%;
        overflow: hidden;
        text-align: left;
        font-size: var(--defaultFontSize);
        background: transparent;
        text-align: center;
        //  border-bottom: 1px solid #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-4-player {
          width: 20% !important;
        }

        & .plues-section {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80%;

          & .under-pules {
            width: 5px;
            border-bottom: 1px solid #FFC900;
            margin-right: 1px;
          }
        }
      }

      &-id-header {
        width: 13%;
        background: transparent;
        text-align: center;
        //  border-bottom: 1px solid #fff;
        padding-left: 5px;

        &-4-player {
          width: 10% !important;
        }
      }

      &-pules-header {
        width: 18%;
        background: transparent;
        text-align: center;

        &-4-player {
          width: 10% !important;
        }
        //  border-bottom: 1px solid #fff;
        & .plues-section {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90%;

          & .under-pules {
            width: 5px;
            border-bottom: 1px solid #FFC900;
            margin-right: 1px;
          }
        }
      }

      &-row {
        //  display: block;
        height: 22px;
      }

      &-id-col {
        color: #FFF;
        width: 13%;
        font-size: var(--smallFontSize);
        padding-left: 5px;
        text-align: center;

        &-4-player {
          width: 10% !important;
        }

        &-div {
          width: 35px;
        }
      }

      &-pules-col {
        font-size: var(--smallFontSize);
        width: 18%;
        text-align: center;
        max-width: 38px;
        min-width: 38px;

        &-div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-col {
        width: 23%;
        text-align: center;
        font-size: var(--smallFontSize);

        &-4-player {
          width: 20% !important;
        }
      }

      &-points {
        width: 100%;
        margin: 0;
        height: 20px;
        padding-top: 1px;
        font-size: var(--defaultFontSize);
        border-top: 1px solid #fff;

        &-col {
          text-align: center;
          width: 23%;
          padding-left: 0;
          padding-right: 0;

          &-4-player {
            width: 20% !important;
          }

          &-id {
            text-align: center;
            //  width: 10%;
            //  padding-left: 10px;
            font-size: var(--mSmallFontSize);
            padding-left: 5px;
            //  width: 13%;
            min-width: 37px;
            max-width: 37px;
            width: 37px;
            //  div {
            //    width: 35px;
            //  }
            &-4-player {
              width: 10% !important;
            }
          }

          &-pules {
            text-align: center;
            //  width: 18%;
            min-width: 53px;
            max-width: 53px;
            width: 53px;

            &-4-player {
              width: 10% !important;
              min-width: unset !important;
              max-width: unset !important;
            }
          }
        }
      }

      &-sum {
        width: 100%;
        margin: 0;
        height: 20px;
        padding-top: 1px;
        font-size: var(--defaultFontSize);

        &-light {
          background: rgba(255, 255, 255, 0.5) !important;
          border-radius: 15px;
        }

        &-normal {
          background: rgba(255, 255, 255, 0.5) !important;
          border-radius: 15px;
        }

        &-dark {
          background: rgba(0,0,0,0.5) !important;
          border-radius: 15px;
        }

        &-col {
          text-align: center;
          width: 23%;
          padding-left: 0;
          padding-right: 0;

          &-4-player {
            width: 20% !important;
          }

          &-id {
            text-align: center;
            //  width: 13%;
            padding-left: 5px;
            min-width: 37px;
            max-width: 37px;
            width: 37px;

            &-4-player {
              width: 10% !important;
              min-width: unset !important;
              max-width: unset !important;
            }

            & div {
              //  width: 35px;

              .player-balance-coin {
                width: 17px;
                height: 17px;
                margin-left: 7px;
                margin-bottom: 3px;
              }
            }
          }

          &-pules {
            text-align: center;
            //  width: 18%;
            min-width: 53px;
            max-width: 53px;
            width: 53px;

            &-4-player {
              width: 10% !important;
              min-width: unset !important;
              max-width: unset !important;
            }
          }
        }
      }

      &-scroll {
        &-area {
          height: 88px;
          border-top: 1px solid #fff;

          &-wrapper {
            position: relative;
          }
        }

        &-content {
          //  margin-right: 7px;
          //  width: 95%;
        }
      }

      &-toggle {

        &-open {
          position: absolute;
          right: 25px;
        }

        &-closed {
          position: absolute;
          top: 633px;
          right: 25px;
        }

        &-image {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }
    }

    .table {
      border-radius: 3px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 20%, #383838 100%);
      font-size: var(--defaultFontSize);
      font-weight: 300;
      color: #fff;

      th, td {
        padding: 3px 5px;
        border: none;
      }

      td:first-child {
        color: $white;
      }
    }
    //
    //  Cards
    //

    .cards {
      position: absolute;
      top: 260px;
      left: 438px;
      transition: 0.3s ease;

      &-odd {
        left: 426px;
      }

      &-even {
        left: 445px;
      }

      .card {
        width: 110px;
        height: 164px;
        background-color: transparent;
        float: left;
        margin-right: -58px;
        border: none;
        transition: 0.3s ease;
        cursor: pointer;
        position: absolute;
        transform-origin: top left;
        background-size: 110px 164px;
        background-position: top center;
        background-repeat: no-repeat;

        &-♠︎-9 {
          //  @include card-2x( '../../../images/cards-white-fill/♠︎-9', 'png', 110px, 164px, top center, no-repeat );
          background-image: url('../../../images/cards-white-fill/♠︎-9.png');
        }

        &-♠︎-10 {
          //  @include card-2x( '../../../images/cards-white-fill/♠︎-10', 'png', 110px, 164px, top center, no-repeat );
          background-image: url('../../../images/cards-white-fill/♠︎-10.png');
        }

        &-♠︎-A {
          //  @include card-2x( '../../../images/cards-white-fill/♠︎-A', 'png', 110px, 164px, top center, no-repeat );
          background-image: url('../../../images/cards-white-fill/♠︎-A.png');
        }

        &-♠︎-J {
          //  @include card-2x( '../../../images/cards-white-fill/♠︎-J', 'png', 110px, 164px, top center, no-repeat );
          background-image: url('../../../images/cards-white-fill/♠︎-J.png');
        }

        &-♠︎-Q {
          //  @include card-2x( '../../../images/cards-white-fill/♠︎-K', 'png', 110px, 164px, top center, no-repeat );
          background-image: url('../../../images/cards-white-fill/♠︎-Q.png');
        }

        &-♠︎-K {
          //  @include card-2x( '../../../images/cards-white-fill/♠︎-Q', 'png', 110px, 164px, top center, no-repeat );
          background-image: url('../../../images/cards-white-fill/♠︎-K.png');
        }

        &-♣︎-9 {
          background-image: url('../../../images/cards-white-fill/♣︎-9.png');
        }

        &-♣︎-10 {
          background-image: url('../../../images/cards-white-fill/♣︎-10.png');
        }

        &-♣︎-A {
          background-image: url('../../../images/cards-white-fill/♣︎-A.png');
        }

        &-♣︎-J {
          background-image: url('../../../images/cards-white-fill/♣︎-J.png');
        }

        &-♣︎-Q {
          background-image: url('../../../images/cards-white-fill/♣︎-Q.png');
        }

        &-♣︎-K {
          background-image: url('../../../images/cards-white-fill/♣︎-K.png');
        }

        &-♥-9 {
          background-image: url('../../../images/cards-white-fill/♥-9.png');
        }

        &-♥-10 {
          background-image: url('../../../images/cards-white-fill/♥-10.png');
        }

        &-♥-A {
          background-image: url('../../../images/cards-white-fill/♥-A.png');
        }

        &-♥-J {
          background-image: url('../../../images/cards-white-fill/♥-J.png');
        }

        &-♥-Q {
          background-image: url('../../../images/cards-white-fill/♥-Q.png');
        }

        &-♥-K {
          background-image: url('../../../images/cards-white-fill/♥-K.png');
        }

        &-♦︎-7 {
          background-image: url('../../../images/cards-white-fill/♦︎-7.png');
        }

        &-♦︎-8 {
          background-image: url('../../../images/cards-white-fill/♦︎-8.png');
        }

        &-♦︎-9 {
          background-image: url('../../../images/cards-white-fill/♦︎-9.png');
        }

        &-♦︎-10 {
          background-image: url('../../../images/cards-white-fill/♦︎-10.png');
        }

        &-♦︎-A {
          background-image: url('../../../images/cards-white-fill/♦︎-A.png');
        }

        &-♦︎-J {
          background-image: url('../../../images/cards-white-fill/♦︎-J.png');
        }

        &-♦︎-Q {
          background-image: url('../../../images/cards-white-fill/♦︎-Q.png');
        }

        &-♦︎-K {
          background-image: url('../../../images/cards-white-fill/♦︎-K.png');
        }

        &-0 {
          left: -225px;
          top: 55px;
          transform: rotate(-19deg);
        }

        &-1 {
          left: -175px;
          top: 41px;
          transform: rotate(-14.41deg);
        }

        &-2 {
          left: -125px;
          top: 27px;
          transform: rotate(-10.46deg);
        }

        &-3 {
          left: -75px;
          top: 16px;
          transform: rotate(-7.23deg);
        }

        &-4 {
          left: -25px;
          top: 8px;
          transform: rotate(-3.89deg);
        }

        &-5 {
          left: 25px;
          top: 5px;
          transform: rotate(-0deg);
        }

        &-6 {
          left: 75px;
          top: 5px;
          transform: rotate(3.89deg);
        }

        &-7 {
          left: 123px;
          top: 8px;
          transform: rotate(7.23deg);
        }

        &-8 {
          left: 170px;
          top: 15px;
          transform: rotate(10.46deg);
        }

        &-9 {
          left: 220px;
          top: 24px;
          transform: rotate(14deg);
        }
        /*&-selected {
      margin-top: -20px;
    }

    &-played {
      margin-top: -40px;
    }*/
      }



      .blocked {
        margin-top: 0px;

        &-overlay {
          position: relative;
          //  width: 100%;
          //  height: 100%;

          height: 158px;
          width: 104px;
          margin-top: 0;
          margin-left: 3px;
          margin-right: 5px;
          padding-right: 10px;
          border-radius: 5px;
          cursor: default;
          background: rgba(0, 0, 0, 0.20),
        }
      }

      .allowed {
        margin-top: -10px;

        &:hover {
          margin-top: -20px;
        }
      }

      .selected {
        margin-top: -20px;
        transition: none !important;
      }
    }


    .played-cards {
      position: absolute;
      top: 80px;
      left: 405px;
      z-index: 998;

      &-sittingOut {
        top: 210px;
        left: 405px;
      }
      // left card
      .card-0 {
        top: 10px;
        left: -38px;
        position: absolute;
        //  z-index: 10;
      }
      // bottom card
      .card-1 {
        top: 30px;
        left: 40px;
        position: absolute;
        //  z-index: 30;
      }
      // right card
      .card-2 {
        top: 10px;
        left: 115px;
        position: absolute;
        //  z-index: 20;
      }
      // top card
      .card-3 {
        top: -10px;
        left: 40px;
        position: absolute;
        //  z-index: 20;
      }

      .card-order-0 {
        z-index: 10;
      }

      .card-order-1 {
        z-index: 20;
      }

      .card-order-2 {
        z-index: 30;
      }

      .card {
        //  width: 84px;
        //  height: 125px;

        width: 75px; //
        height: 112px; //
        background-size: 75px 112px; //
        // width: 100px;
        // height: 149px;
        // background-size: 100px 149px;

        background-color: transparent;
        float: left;
        margin-right: -58px;
        border: none;
        transition: none !important;
        cursor: pointer;
        background-position: top center;
        background-repeat: no-repeat;


        &-♠︎-9 {
          //  @include card-2x( '../../../images/cards-white-fill/♠︎-9', 'png', 100px, 149px, top center, no-repeat );
          background-image: url('../../../images/cards-white-fill/♠︎-9.png');
        }

        &-♠︎-10 {
          background-image: url('../../../images/cards-white-fill/♠︎-10.png');
        }

        &-♠︎-A {
          background-image: url('../../../images/cards-white-fill/♠︎-A.png');
        }

        &-♠︎-J {
          background-image: url('../../../images/cards-white-fill/♠︎-J.png');
        }

        &-♠︎-Q {
          background-image: url('../../../images/cards-white-fill/♠︎-Q.png');
        }

        &-♠︎-K {
          background-image: url('../../../images/cards-white-fill/♠︎-K.png');
        }

        &-♣︎-9 {
          background-image: url('../../../images/cards-white-fill/♣︎-9.png');
        }

        &-♣︎-10 {
          background-image: url('../../../images/cards-white-fill/♣︎-10.png');
        }

        &-♣︎-A {
          background-image: url('../../../images/cards-white-fill/♣︎-A.png');
        }

        &-♣︎-J {
          background-image: url('../../../images/cards-white-fill/♣︎-J.png');
        }

        &-♣︎-Q {
          background-image: url('../../../images/cards-white-fill/♣︎-Q.png');
        }

        &-♣︎-K {
          background-image: url('../../../images/cards-white-fill/♣︎-K.png');
        }

        &-♥-9 {
          background-image: url('../../../images/cards-white-fill/♥-9.png');
        }

        &-♥-10 {
          background-image: url('../../../images/cards-white-fill/♥-10.png');
        }

        &-♥-A {
          background-image: url('../../../images/cards-white-fill/♥-A.png');
        }

        &-♥-J {
          background-image: url('../../../images/cards-white-fill/♥-J.png');
        }

        &-♥-Q {
          background-image: url('../../../images/cards-white-fill/♥-Q.png');
        }

        &-♥-K {
          background-image: url('../../../images/cards-white-fill/♥-K.png');
        }

        &-♦︎-7 {
          background-image: url('../../../images/cards-white-fill/♦︎-7.png');
        }

        &-♦︎-8 {
          background-image: url('../../../images/cards-white-fill/♦︎-8.png');
        }

        &-♦︎-9 {
          background-image: url('../../../images/cards-white-fill/♦︎-9.png');
        }

        &-♦︎-10 {
          background-image: url('../../../images/cards-white-fill/♦︎-10.png');
        }

        &-♦︎-A {
          background-image: url('../../../images/cards-white-fill/♦︎-A.png');
        }

        &-♦︎-J {
          background-image: url('../../../images/cards-white-fill/♦︎-J.png');
        }

        &-♦︎-Q {
          background-image: url('../../../images/cards-white-fill/♦︎-Q.png');
        }

        &-♦︎-K {
          background-image: url('../../../images/cards-white-fill/♦︎-K.png');
        }
      }
    }
    //
    // Colors
    //
    .red {
      color: $red;
    }

    .green {
      color: $green;
    }

    @keyframes pulse {
      0% {
        filter: blur(3px);
      }
    
      50% {
        filter: blur(1vh);
      }
    
      100% {
        filter: blur(3px);
      }
    }

    .pulse-blur {
      animation: pulse 1.5s infinite;
    }

    @keyframes move-up {
      0% { transform: translateY(0px); }
      55% { transform: translateY(-11px); }
      100% { transform: translateY(0px); }
    }
    @keyframes move-down {
      0% { transform: translateY(0px); }
      55% { transform: translateY(11px); }
      100% { transform: translateY(0px); }
    }
    @keyframes move-left {
      0% { transform: translateX(0px); }
      45% { transform: translateX(-10px); }
      100% { transform: translateX(0px); }
    }
    @keyframes move-right {
      0% { transform: translateX(0px); }
      45% { transform: translateX(10px); }
      100% { transform: translateX(0px); }
    }

    @keyframes move-up-2 {
      0% { transform: translateY(0px); }
      40% { transform: translateY(-8px); }
      100% { transform: translateY(0px); }
    }
    @keyframes move-down-2 {
      0% { transform: translateY(0px); }
      40% { transform: translateY(8px); }
      100% { transform: translateY(0px); }
    }
    @keyframes move-left-2 {
      0% { transform: translateX(0px); }
      60% { transform: translateX(-10px); }
      100% { transform: translateX(0px); }
    }
    @keyframes move-right-2 {
      0% { transform: translateX(0px); }
      60% { transform: translateX(10px); }
      100% { transform: translateX(0px); }
    }

    .drunk-move-up {
      animation: move-up 1.8s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .drunk-move-down {
      animation: move-down 1.8s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .drunk-move-left {
      animation: move-left 1.6s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .drunk-move-right {
      animation: move-right 1.6s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .drunk-move-up-2 {
      animation: move-up-2 1.9s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .drunk-move-down-2 {
      animation: move-down-2 1.9s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .drunk-move-left-2 {
      animation: move-left-2 2.1s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .drunk-move-right-2 {
      animation: move-right-2 2.1s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out
    }
    .middle-card {
      @keyframes move-up-middle {
        0% { transform: translateY(0px); }
        55% { transform: translateY(-11px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-down-middle {
        0% { transform: translateY(0px); }
        55% { transform: translateY(11px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-left-middle {
        0% { transform: translateX(0px); }
        45% { transform: translateX(-10px); }
        100% { transform: translateX(0px); }
      }
      @keyframes move-right-middle {
        0% { transform: translateX(0px); }
        45% { transform: translateX(10px); }
        100% { transform: translateX(0px); }
      }
      @keyframes move-up-2-middle {
        0% { transform: translateY(0px); }
        40% { transform: translateY(-8px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-down-2-middle {
        0% { transform: translateY(0px); }
        40% { transform: translateY(8px); }
        100% { transform: translateY(0px); }
      }
      @keyframes move-left-2-middle {
        0% { transform: translateX(0px); }
        60% { transform: translateX(-10px); }
        100% { transform: translateX(0px); }
      }
      @keyframes move-right-2-middle {
        0% { transform: translateX(0px); }
        60% { transform: translateX(10px); }
        100% { transform: translateX(0px); }
      }

      &-drunk-move-up {
        animation: move-up-middle 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down {
        animation: move-down-middle 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left {
        animation: move-left-middle 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right {
        animation: move-right-middle 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-up-2 {
        animation: move-up-2-middle 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down-2 {
        animation: move-down-2-middle 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left-2 {
        animation: move-left-2-middle 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right-2 {
        animation: move-right-2-middle 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
    }
    .left-card {
      @keyframes move-up-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        55% { transform: rotate(calc(-25deg)) translateY(-11px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-down-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        55% { transform: rotate(calc(-25deg)) translateY(11px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-left-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        45% { transform: rotate(calc(-25deg)) translateX(-10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }
      @keyframes move-right-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        45% { transform: rotate(calc(-25deg)) translateX(10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }
      @keyframes move-up-2-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        40% { transform: rotate(calc(-25deg)) translateY(-8px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-down-2-left {
        0% { transform: rotate(calc(-25deg)) translateY(0px); }
        40% { transform: rotate(calc(-25deg)) translateY(8px); }
        100% { transform: rotate(calc(-25deg)) translateY(0px); }
      }
      @keyframes move-left-2-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        60% { transform: rotate(calc(-25deg)) translateX(-10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }
      @keyframes move-right-2-left {
        0% { transform: rotate(calc(-25deg)) translateX(0px); }
        60% { transform: rotate(calc(-25deg)) translateX(10px); }
        100% { transform: rotate(calc(-25deg)) translateX(0px); }
      }

      &-drunk-move-up {
        animation: move-up-left 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down {
        animation: move-down-left 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left {
        animation: move-left-left 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right {
        animation: move-right-left 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-up-2 {
        animation: move-up-2-left 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down-2 {
        animation: move-down-2-left 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left-2 {
        animation: move-left-2-left 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right-2 {
        animation: move-right-2-left 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
    }
    .right-card {
      @keyframes move-up-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        55% { transform: rotate(calc(25deg)) translateY(-11px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-down-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        55% { transform: rotate(calc(25deg)) translateY(11px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-left-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        45% { transform: rotate(calc(25deg)) translateX(-10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }
      @keyframes move-right-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        45% { transform: rotate(calc(25deg)) translateX(10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }
      @keyframes move-up-2-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        40% { transform: rotate(calc(25deg)) translateY(-8px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-down-2-right {
        0% { transform: rotate(calc(25deg)) translateY(0px); }
        40% { transform: rotate(calc(25deg)) translateY(8px); }
        100% { transform: rotate(calc(25deg)) translateY(0px); }
      }
      @keyframes move-left-2-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        60% { transform: rotate(calc(25deg)) translateX(-10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }
      @keyframes move-right-2-right {
        0% { transform: rotate(calc(25deg)) translateX(0px); }
        60% { transform: rotate(calc(25deg)) translateX(10px); }
        100% { transform: rotate(calc(25deg)) translateX(0px); }
      }

      &-drunk-move-up {
        animation: move-up-right 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down {
        animation: move-down-right 1.8s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left {
        animation: move-left-right 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right {
        animation: move-right-right 1.6s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-up-2 {
        animation: move-up-2-right 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-down-2 {
        animation: move-down-2-right 1.9s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-left-2 {
        animation: move-left-2-right 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
      &-drunk-move-right-2 {
        animation: move-right-2-right 2.1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out
      }
    }
  }

  .app-wrapper.campaign-game-section {
    .player-firstperson {
      bottom: 70px !important;
    }
  }

  .end-result-party-log-modal {
    & .scrollArea-buttonUp {
      display: none;
    }

    & .scrollArea-buttonDown {
      display: none;
    }

    & .scrollbar {
      width: 5px !important;
      background: #FFFFFF !important;
      opacity: 0.1 !important;
      border-radius: 4px !important;
    }
  }

.end-result-party-modal {
  font-size: var(--defaultFontSize) !important;

  &-divide {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 5px;
    margin-bottom: 5px;

    &-light {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding-bottom: 5px;
      margin-bottom: 5px;
    }

    &-normal {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 5px;
      margin-bottom: 5px;
    }

    &-dark {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }

  &-party-log-time {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: var(--defaultFontSize);
    line-height: 160%;
    color: #FFFFFF;
    opacity: 0.7;
  }

  &-cards {
    display: inline-block;
    height: 98px;

    &-table {
      display: inline-block;
      height: 98px;
      margin-left: 8px;
    }

    &-photo-container {
      height: 100%;
      float: left;
      margin-right: 6px;

      &-img {
        width: 36px;
        height: 36px;
        border-radius: 50%;

        &-default {
          &-light {
            border: 2px solid #aaaaaa;
          }

          &-normal {
            border: 1px solid rgba(0, 0, 0, 0.5);
          }

          &-dark {
            background-color: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(0, 0, 0, 0.5);
          }
        }
      }
    }

    &-info {
      height: 100%;
      float: left;
      width: 155px;

      &-table {
        height: 100%;
        float: left;

        &-title {
          display: block;
          padding-top: 8px;
          // opacity: 0.5;
        }
      }

      &-title {
        display: block;
        padding-top: 8px;
      }

      &-card-container {
        position: relative;
        display: block;

        &-wrapper {
          width: 50px;
          position: absolute;

          &-card {
            width: 100%;
            height: auto;
            box-shadow: inset 0px 0px 10px rgba(181, 174, 149, 0.2);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.35));
            //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
          }
        }

        &-wrapper-0 {
          left: 0px;
        }

        &-wrapper-1 {
          left: 13px;
        }

        &-wrapper-2 {
          left: 26px;
        }

        &-wrapper-3 {
          left: 39px;
        }

        &-wrapper-4 {
          left: 52px;
        }

        &-wrapper-5 {
          left: 65px;
        }

        &-wrapper-6 {
          left: 78px;
        }

        &-wrapper-7 {
          left: 91px;
        }

        &-wrapper-8 {
          left: 104px;
        }

        &-wrapper-9 {
          left: 117px;
        }

        &-wrapper-10 {
          left: 130px;
        }

        &-wrapper-11 {
          left: 143px;
        }
      }
    }
  }
}

  .end-result-modal {
    top: 75px !important;
    min-width: 500px;

    &-iframe-banner-mode {
      top: 2px !important;
      margin-top: 5px;
    }

    .modal-content {
      border: 0;
      border-radius: 25px;
    }

    &-info-button {
      color: #FFF;
      text-decoration: none;
      padding: 2px 4px;
      font-size: var(--defaultFontSize);

      &:hover {
        color: #fff;
        text-decoration: none;
      }

      &:focus {
        color: #fff;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        text-decoration: none;
      }
    }

    &-header {
      margin-bottom: 10px;
      margin-left: 15px;
      margin-top: 5px;

      .modal-title {
        text-align: left !important;
      }

      &-image {
        height: 25px;
        margin-right: 10px;
        display: inline-block;
      }

      &-text {
        text-transform: uppercase;
        text-align: left;
        display: inline-block;
      }
    }

    &-close-info {
      font-size: var(--largeFontSize);
      font-weight: 600;
      // padding-top: 5px;
      padding: 10px 20px;
      background: #C44C4C;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      // & .
      & .info-image {
        width: 20%;
      }

      & .close-reason {
        width: 80%;
      }
    }

    &-body {
    }

    &-footer {
      &-button {
        width: 120px;
        border-radius: 20px;
        padding: 4px;
        color: #FFFFFF;
        text-align: center;
        height: 32px;
        background-color: #2c5955;
        border: 1px solid #fff;
        -webkit-box-shadow: 5px 5px 0px 0px #380000;
        -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 5px 5px 0px 0px #380000;
        text-align: center;
        font-size: var(--defaultFontSize);

        &:hover {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }


    .score-table {
      width: 100%;
      border: 1px solid #fff;
      color: #fff;
      font-size: var(--defaultFontSize);

      &-fourPlayer {
        width: 100%;

        &-scroll-content {
          width: 95%;
        }

        & .score-table {

          &-header {
            width: 12.5%;
          }

          &-id-header {
            width: 20%;
          }

          &-pules-header {
            width: 16%;
          }

          &-info-header {
            width: 14%;
          }

          &-id-col {
            width: 20%;
          }

          &-pules-col {
            width: 14%;
          }

          &-info-col {
            width: 14%;
          }

          &-col {
            width: 12.5%;
          }

          &-points {
            &-col {
              width: 12.5%;

              &-id {
                width: 20%;
              }

              &-pules {
                width: 16%;
              }

              &-info {
                width: 10%;
              }
            }
          }

          &-sum {
            &-col {
              width: 12.5%;

              &-id {
                width: 20%;
              }

              &-pules {
                width: 16%;
              }
            }
          }
        }
      }
      /*  &-sum-fourPlayer {
      width: 96%;

      & .score-table {
          border: 1px solid red;

          &-col{
            width: 12.5%;

            &-id {
              width: 20%;
            }

            &-pules {
              width: 16%;
            }

            &-info {
              width: 10%;
            }
          }
      }
    }

    &-points-fourPlayer {
      width: 96%;

      & .score-table {
          border: 1px solid red;

          &-col{
            width: 12.5%;

            &-id {
              width: 20%;
            }

            &-pules {
              width: 16%;
            }

            &-info {
              width: 10%;
            }
          }
      }
    }  */

      & tr {
        display: flex;
        text-align: center;
      }

      &-header {
        //  width: 23%;
        overflow: hidden;
        text-align: left;
        font-size: var(--largeFontSize);
        background: transparent;
        text-align: center;
        width: 18%;
        //  border: 1px solid red;
      }

      &-id-header {
        //  width: 13%;
        background: transparent;
        text-align: center;
        padding-left: 5px;
        width: 20%;
        //  border: 1px solid blue;
      }

      &-pules-header {
        //  width: 18%;
        background: transparent;
        text-align: center;
        width: 16%;
        font-size: var(--largeFontSize);
        //  border: 1px solid yellow;
      }

      &-info-header {
        //  width: 18%;
        background: transparent;
        text-align: center;
        width: 10%;
        //  border: 1px solid green;
      }

      &-row {
        //  display: block;
        height: 32px;
      }

      &-id-col {
        color: #FFF;
        //  width: 13%;
        font-size: var(--defaultFontSize);
        line-height: 25px;
        padding-left: 5px;
        //  text-align: center;
        width: 20%;
        //  border: 1px solid blue;

        &-div {
          width: 35px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-pules-col {
        font-size: var(--smallFontSize);
        line-height: 25px;
        //  width: 18%;
        //  text-align: center;
        //  max-width: 40px;
        width: 16%;
        //  border: 1px solid yellow;

        &-div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-info-col {
        font-size: var(--smallFontSize);
        //  text-align: center;
        width: 10%;
        //  border: 1px solid green;

        &-div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-col {
        //  width: 23%;
        //  text-align: center;
        font-size: var(--defaultFontSize);
        line-height: 25px;
        width: 18%;
        //  border: 1px solid red;
      }

      &-points {
        width: 100%;
        margin: 0;
        height: 20px;
        padding-top: 1px;
        font-size: var(--defaultFontSize);
        border-top: 1px solid #fff;

        &-col {
          //  text-align: center;
          //  width: 23%;
          padding-left: 0;
          padding-right: 0;
          width: 18%;
          //  border: 1px solid red;

          &-id {
            //  text-align: center;
            font-size: var(--defaultFontSize);
            padding-left: 5px;
            //  min-width: 55px;
            //  max-width: 55px;
            //  width: 55px;
            width: 20%;
            //  border: 1px solid blue;
          }

          &-pules {
            //  text-align: center;
            //  min-width: 55px;
            //  max-width: 55px;
            //  width: 55px;
            width: 16%;
            //  border: 1px solid yellow;
          }

          &-info {
            //  text-align: center;
            width: 10%;
            //  border: 1px solid green;
          }
        }
      }

      &-sum {
        width: 100%;
        margin: 0;
        height: 20px;
        padding-top: 1px;
        font-size: var(--defaultFontSize);

        &-col {
          //  text-align: center;
          //  width: 23%;
          padding-left: 0;
          padding-right: 0;
          width: 18%;
          //  border: 1px solid red;

          &-id {
            //  text-align: center;
            //  width: 13%;
            //  min-width: 55px;
            //  max-width: 55px;
            //  width: 55px;
            width: 20%;
            //  border: 1px solid blue;

            & div {
              .player-balance-coin {
                width: 17px;
                height: 17px;
                //  margin-left: 20px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 3px;
              }
            }
          }

          &-pules {
            //  text-align: center;
            //  width: 18%;
            //  min-width: 55px;
            //  max-width: 55px;
            //  width: 55px;

            width: 16%;
            //  border: 1px solid yellow;
          }

          &-info {
            //  text-align: center;
            width: 10%;
            //  border: 1px solid green;
          }
        }
      }

      &-scroll {
        &-area {
          height: 200px;
          border-top: 1px solid #fff;
        }

        &-content {
          //  margin-right: 7px;
          //  width: 96%;
        }
      }
    }
  }
  //
  //  Customize progressbar
  //
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #9bf334;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar {
    width: 100%;
    height: 100%;
  }

  .block-button {
    z-index: 50;
    border-radius: 15px;
    padding: 4px;
    padding-left: 5px;
    color: #FFD34E;
    text-align: center;
    width: 28px;
    height: 28px;
    position: absolute;
    //left: 85px;
    top: 14px;
    right: 2%;
    left: unset;
    background-color: #2c5955;
    border: 1px solid #fff;
    -webkit-box-shadow: 2px 2px 0px 0px #380000;
    -moz-box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 0px 0px #380000;
    text-align: center;

    &:hover {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
    }

    &:focus {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
    }

    &:active {
      color: #fff;
      background-color: #380000;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .report-button {
    z-index: 50;
    border-radius: 15px;
    padding: 4px;
    padding-left: 5px;
    color: #FFD34E;
    text-align: center;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 20%;
    left: -2%;
    right: unset;
    background-color: #2c5955;
    border: 1px solid #fff;
    -webkit-box-shadow: 2px 2px 0px 0px #380000;
    -moz-box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 0px 0px #380000;
    text-align: center;
    min-width: 1.1vw;
    min-height: 1.1vw;
    border-radius: 50px;

    &-fullscreen {
      top: 20%;
      left: -2%;
      right: unset;
    }

    &-image {
      width: 100%;
      padding: 0;
      height: 100%;
      margin: 0;
    }

    &:hover {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
    }

    &:focus {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
    }

    &:active {
      color: #fff;
      background-color: #380000;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .root-is-app-frame {
    .report-button {
      top: 20%;
      left: -2%;
      right: unset;
    }
  }

  .root-is-app-frame {
    .selection {
      &-4-player-room {
        transform: translate(-50%, -100%);
        top: 345px !important;
        bottom: unset;
      }
    }
  }

  .selection {
    position: fixed;
    top: 30px;
    left: 50%;
    width: 200px;
    z-index: 999;
    // height: 250px;
    // margin: 15px;
    transform: translateX(-50%);
    background-color: #2c5955;
    border: 1px solid #fff;
    box-shadow: 5px 5px 0 0 #380000;

    &-4-player-room {
      // transform: translate(-50%, -100%);
      // top: 345px !important;
      bottom: 51%;
      top: unset !important;
    }

    .row {
      width: 80%;
      height: 35px;
      margin: 12px 10%;
      font-size: var(--defaultFontSize);

      .selection-take {
        width: 100%;
        margin: auto;
        background-color: #c44c4c;
        border-radius: 15px;
        height: 30px;
        font-size: var(--defaultFontSize);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: 5px 5px 0px 0px #380000;
        -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 5px 5px 0px 0px #380000;

        &:hover {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #c44c4c;
          text-decoration: none;
        }

        &:focus {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #c44c4c;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }

      .selection-pass {
        width: 100%;
        margin: auto;
        background-color: #2c5955;
        border-radius: 15px;
        height: 30px;
        font-size: var(--smallFontSize);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: 5px 5px 0px 0px #380000;
        -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 5px 5px 0px 0px #380000;

        &:hover {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }

  .automation {
    // position: absolute;
    // top: 30px;
    // left: 38%;
    // width: 200px;
    // z-index: 999;
    // margin: 15px;
    // background-color: #2c5955;
    // border: 1px solid #fff;
    // box-shadow: 5px 5px 0 0 #380000;
    // .row {
    //   width: 80%;
    //   height: 35px;
    //   margin: 12px 10%;
    //   font-size: var(--defaultFontSize);

    .automation-button {
      width: 200px;
      margin: auto;
      background-color: #c44c4c;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #c44c4c;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #c44c4c;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
    //}
  }

  .top-left {
    position: absolute;
    top: 25px;
    left: 30px;
    z-index: 100;
    cursor: pointer;

    &-exit {
      float: left;
      background: transparent;
      border: 0;
      font-style: normal;
      font-weight: normal;
      font-size: var(--defaultFontSize);
      line-height: 16px;
      text-align: center;
      color: #fff;

      &-button {
        background: transparent;
        border: 0;
      }
    }

    &-gameType {
      margin-top: 10px;
      margin-left: 15px;
      float: left;

      & .gameType {
        position: relative;
      }
    }

    &-fast {
      margin-top: 10px;
      margin-left: 10px;
      float: left;
      cursor: default;
    }

    &-pro {
      margin-top: 10px;
      margin-left: 10px;
      float: left;
      cursor: default;
    }

    &-minGames {
      position: relative;
      // margin-top: 10px;
      margin-left: 10px;
      float: left;
      cursor: default;

      &-text {
        width: 33px;
        height: 25px;
        position: absolute;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;
        font-style: normal;
        font-weight: 600;
        font-size: var(--defaultFontSize);
        // font-size: 13px;

        text-align: center;
        letter-spacing: 0.5px;
        color: #1E413D;
      }

      &-img {
        width: 33px;
        height: 33px;
      }
    }

    & .fourPRoom {
      width: 20px;
      height: 24px;
      background: #C4C4C4;
      border-radius: 2px;

      &-text {
        width: 20px;
        height: 18px;
        margin: auto;
        font-style: normal;
        font-weight: 600;
        font-size: var(--largeFontSize);
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #1E413D;
      }
    }
  }

  @media (max-width: 1280px) {
    .test-modal-button {
      left: 38px !important;
      top: 85px !important;
    }
  }

  .test-modal {
    &-button {
      width: auto;
      left: 10%;
      position: absolute;
      top: 25px;
      // padding-left: 2px;
      // padding-right: 2px;
      padding: 7px 10px !important;
      margin: auto;
      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      // font-size: 15px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 30px;
        font-size: var(--largeFontSize);
        // font-size: 15px;
      }
    }
  }

  .last-round {
    position: fixed;
    cursor: pointer;
    width: 120px;
    right: 36%;
    bottom: 2%;

    & .active {
      opacity: 0.5;
    }

    &-button {
      width: 120px;
      padding-left: 2px;
      padding-right: 2px;
      margin: auto;
      background-color: #2c5955;
      border-radius: 15px;
      height: 46px !important;
      font-size: var(--largeFontSize);
      // font-size: 15px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &-text {
        max-width: 100%;
        overflow: hidden;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 30px;
        font-size: var(--largeFontSize);
        // font-size: 15px;
      }

      &-ru {
        font-size: var(--mSmallFontSize);
        line-height: 10px;
        padding-left: 1px;
        padding-right: 1px;
      }
    }

    &-popover {
      background-color: #2c5955;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      pointer-events: none !important;

      &-body {
        color: #fff;
      }
    }

    &-image {
      margin: auto;
      margin-bottom: 3px;
    }
  }

  .quit-round {
    position: fixed;
    cursor: pointer;
    width: 120px;
    left: 36%;
    bottom: 2%;

    & .active {
      opacity: 0.5;
    }

    &-button {
      width: 120px;
      padding-left: 2px;
      padding-right: 2px;
      margin: auto;
      background-color: #2c5955;
      border-radius: 15px;
      height: 46px !important;
      font-size: var(--largeFontSize);
      // font-size: 15px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &-text {
        max-width: 100%;
        overflow: hidden;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &-image {
      margin: auto;
      margin-bottom: 3px;
    }
  }

  .current-game-type {
    position: absolute;
    top: 5px;
    right: 225px;
    cursor: default;
    //display: none;
    z-index: 0;

    &-text {
      font-size: var(--xxLargeFontSize);
      // font-size: 26px;
      color: #FFF;
      margin-right: 3px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      line-height: 49px;
      //display: none;
    }
  }

  .turn-timer {

    &-wrapper {
      top: 0px;
      //  top: -5px;
      left: 0px;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 10;

      .timer {
        left: 1px;
        //  top: 3px;
      }
    }
  }

  .gift-modal {
    width: 600px;
    max-width: 600px;

    .modal-content {
      border-radius: 30px !important;

      .modal-header {
        h5 {
          text-align: left;
          font-size: var(--xxLargeFontSize);
          // font-size: 26px;
          font-weight: bold;
          text-transform: uppercase;
          display: inline-block;
          padding-left: 15px;
          padding-top: 15px;


          img {
            width: 45px;
            height: 38px;
            display: inline-block;
            margin-top: -12px;
          }
        }
      }

      .notification-header-close {
        right: 25px;
        top: 20px;
        position: absolute;
      }
    }

    &-body {

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0px;
        border-left: 3px solid #2c5955;
        border-right: 3px solid #2c5955;
      }

      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 0px;
        border-left: 4px solid #2c5955;
        border-right: 4px solid #2c5955;
      }

      &-wrapper {
        padding: 0;
        height: 100%;
        // top: 1px;
      }

      &-scroll {

        &-area {
          height: 100%;
          max-height: 75vh;
          width: 100%;
          margin-right: 2px;

          & .scrollbar {
            //  height: 25px!important;
          }
        }

        &-content {
          margin-right: 15px;
          display: flex;
        }
      }
    }
  }

  .gifts {
    &-gift {
      &-pricebox {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: #2c5955;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 2px 10px;
        border: 1px solid white;
        border-radius: 20px;

        img {
          width: 16px;
        }
      }
      // @include background( '../../images/Gifts/Frame', 'svg', 100%, auto, top center, no-repeat );
      // @include background( '../../images/Gifts/vectorpaint', 'svg', 100%, auto, top center, no-repeat );
      background-image: url('../../../images/Gifts/Frame.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;


      &:hover {
        background-image: url('../../../images/Gifts/vectorpaint.svg');
      }

      &:focus {
        background-image: url('../../../images/Gifts/vectorpaint.svg');
      }

      &-selected {
        background-image: url('../../../images/Gifts/vectorpaint.svg');
      }

      background-position: top center;

      .bg {
        background-image: url('http://www.google.com/images/logo_sm.gif');
        border: 5px solid #000;
        width: 50px;
        height: 50px;
      }

      position: relative;
      display: inline-block;
      width: 20%;
      height: 135px;

      &-image {
        max-width: 98%;
        max-height: 110px;
        height: auto;
        margin: auto;
        position: absolute;
        bottom: 19px;
        right: 0;
        left: 0;
        margin: auto;
      }

      &-name {
        color: #fff;
        font-size: var(--defaultFontSize);
        text-transform: none;
      }

      &-button-type {
        background-color: #2c5955;
        border-radius: 15px;
        height: 35px;
        font-size: var(--defaultFontSize);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: 3px 3px 0px 0px #380000;
        -moz-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 3px 3px 0px 0px #380000;

        &:hover {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #FFD34E !important;
          border-color: #FFD34E !important;
          background-color: #2c5955 !important;
          text-decoration: none !important;
        }
      }

      &-button {
        width: 60%;
        margin: auto;
        position: absolute;
        bottom: 40px;
        right: 0;
        left: 0;
        margin: auto;

        &-buy {
          width: 80%;
          margin-left: -15px;
        }

        &-active {
          width: 60%;
          background-color: #380000 !important;
          color: #fff !important;
          border: 1px solid #fff !important;
          -webkit-box-shadow: none !important;
          -moz-box-shadow: none !important;
          box-shadow: none !important;
        }



        &-price {
          display: inline-block;
        }

        &-coin {
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-left: 2px;
        }
      }

      &-comment {
        &-header {
          display: inline-block;
          width: 60%;
          float: left;
        }

        &-length {
          display: inline-block;
          width: 40%;
          float: right;
        }
      }
    }

    &-player {

      &-row {
        margin-bottom: 12px;
        width: 100%;
        text-align: left;
      }

      &-name {
        color: #fff;
        font-weight: bold;
        font-size: var(--defaultFontSize);
        line-height: 18px;
        text-transform: none;
        text-align: left;
        width: 100%;
        text-align: center;
        padding-left: 0;
        padding-top: 4px;

        &-all {
          width: auto;
          display: inline-block;
          vertical-align: middle;
          padding-left: 10px;
        }
      }

      &-image {
        width: 55px;
        height: 55px;
        border-radius: 50%;

        &-wrapper {
          padding-left: 16px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    &-comment {
      resize: none;
      font-size: var(--defaultFontSize);
      height: 94px;

      &-header {
        display: inline-block;
        margin-right: 3px;
      }

      &-length {
        display: inline-block;
      }

      &-wrapper {
        margin-bottom: 5px;
        text-align: center;

        & textarea {
          font-size: var(--mSmallFontSize);
          height: 94px;
          width: 85%;
          border-radius: 15px;
          display: inline-block;
        }
      }
    }

    &-checkbox {
      /*margin: 0;

    &-wrapper {
      padding-left: 5px;
      padding-right: 5px;
    }*/
      cursor: pointer;

      &-wrapper {
        width: 30px;
        height: 30px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        padding-left: 13px;
      }

      & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      & .checkmark {
        background: #FFFFFF;
        border-radius: 3px;
        margin-top: 5px;
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
      }

      & .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      & input:checked ~ .checkmark:after {
        display: block;
      }

      & .checkmark:after {
        //  @include background-2x( '../../images/Checkbox selector only-o', 'png', 16px,12px, center center, no-repeat );
        background-image: url('../../../images/Checkbox selector only-o.png');
        width: 16px;
        height: 12px;
        position: absolute;
        top: 3px;
        left: 1px;
      }
    }

    &-send-confirmation {
      top: 225px !important;
    }
  }


  .test-panel {
    top: 10px !important;


    &-half-section {
      display: inline-block;
      width: 50%;
      padding-left: 2px;
      padding-right: 2px;
    }

    &-third-section {
      display: inline-block;
      width: 25%;
      padding-left: 2px;
      padding-right: 2px;
    }

    &-input {
      height: 26px;
    }

    &-button {
      display: block !important;
      width: min-content;
      margin-left: auto;
      margin-right: auto;
      white-space: nowrap;
      background-color: #2c5955;
      border-radius: 15px;
      height: 26px !important;
      line-height: 20px !important;
      font-size: var(--smallFontSize) !important;
      margin-top: 5px;
      margin-bottom: 10px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &-top {
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
      }

      &-active {
        width: 40%;
        margin-left: 30%;
        margin-right: 30%;
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: #380000;
        border-radius: 15px;
        height: 30px;
        font-size: var(--smallFontSize);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &-cards-table {
      width: 100%;

      & thead {
        width: 100%;
      }

      & tbody {
        width: 100%;
      }

      & td {
        border: 1px solid #fff;
      }
    }
  }


  .waiting-for-players {
    top: 200px;
    left: 340px;
    position: absolute;
    font-size: var(--xxxLargeFontSize);
    color: #FFF;
    text-transform: uppercase;

    &-text {
      display: inline-block;
    }

    &-image {
      display: inline-block;
      position: absolute;
      top: 12px;
      left: 2px;
      width: 12px;
      height: 16px;
      margin-left: 6px;
      margin-right: 6px;

      &-circle {
        display: inline-block;
        width: 28px;
        height: 28px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px solid #fff;
      }
    }
  }

  .strikeout {
    line-height: 1em;
    position: relative;
  }

  .strikeout::after {
    // border-bottom: 2px solid rgba(12, 12, 12, 0.5);
    border-bottom: 2px solid currentColor;
    content: "";
    left: -3px;
    margin-top: 0px;
    position: absolute;
    right: -3px;
    top: 50%;
  }

  .gameResultNotification {
    margin-top: 15px;
  }

  @media only screen and (width: 768px) {
    .app-wrapper {
      max-width: 980px;
    }
  }

  @media only screen and (width: 576px) {
    .app-wrapper {
      max-width: 720px;
    }
  }

  .ozollapas-image {
    width: 60px;
    height: 100px;
    position: absolute;
    left: -55px;
    top: 0;
    pointer-events: none;

    &-new {
      width: 60px;
      height: 100px;
      position: absolute;
      left: -55px;
      top: 0;
      pointer-events: none;
    }
  }

  .alus-kauss-image {
    position: absolute;
    height: 80px;
    width: 60px;
    top: 310px;
    right: 170px;
    z-index: 500;
  }

  .pupoli-image {
    position: absolute;
    width: 100px;
    top: 260px;
    right: 20%;
    z-index: 500;
    pointer-events: none;

    @media only screen and (max-width: 700px) {
      right: 170px;
    }

    div {
      cursor: auto;
    }

    &-new {
      position: absolute;
      width: 100px;

      top: 40vh;
      right: 20%;
      transform: translate(-50%, -50%);
      z-index: 500;
      pointer-events: none;

      @media only screen and (max-width: 1280px) {
        right: 10px;
      }

      div {
        cursor: auto;
      }
    }
  }

  .alus-image {
    position: absolute;
    top: 260px;
    right: 20%;
    z-index: 500;

    @media only screen and (max-width: 700px) {
      right: 170px;
    }

    div {
      cursor: auto;
    }

    &-new {
      position: absolute;
      top: 260px;
      right: 20%;
      z-index: 500;

      @media only screen and (max-width: 700px) {
        right: 170px;
      }

      div {
        cursor: auto;
      }
    }
  }


  .player-info-modal {
    font-size: var(--defaultFontSize);
    //  top: 55px;
    //  left: 25px;
    width: 346px;
    height: auto;
    position: fixed;
    background-color: #2c5955;
    border: 1px solid #fff;
    box-shadow: 5px 5px 0px 0px #380000;
    z-index: 1000;
    border-radius: 20px;
    text-align: left;
    padding: 5px;

    .player-info-gifts-img-loseLarge60 {
      order: 1;
    }

    .player-info-gifts-img-loseLarge30 {
      order: 2;
    }

    .player-info-gifts-img-loseLarge0 {
      order: 3;
    }

    &-backdrop {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // background-color: #333;
      // opacity: .8;
      // filter: blur(5px);
    }

    &-player-left {
      top: 50%;
      left: 5%;
      transform: translateY(-50%);
    }

    &-player-right {
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
    }

    &-player-firstperson {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-player-top {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-player-sittingOut {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & .modal-content {
      border-radius: 20px;
    }

    &-backdrop.show {
      opacity: 0 !important;
    }

    & .giftsHistory {
      width: 40px;
      height: 40px;
      background-size: contain, auto;
      background-repeat: no-repeat;
      background-position: center center;

      &-image {
        max-height: 40px;
        max-width: 35px;
        margin: auto;
      }

      &-row {
        width: 100%;
      }
    }

    & .giftsHistory-lg {
      width: 60px;
      height: 60px;
      background-size: contain, auto;
      background-repeat: no-repeat;
      background-position: center center;

      &-image {
        max-height: 60px;
        max-width: 60px;
        margin: auto;
      }

      &-row {
        width: 100%;
      }
    }

    & .player-info {

      &-header {
        //  text-align: center;
        font-size: var(--xLargeFontSize);
        // font-size: 22px;
        line-height: 24px;
        padding: 6px;
        text-transform: uppercase;

        &-img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          display: inline-block;
        }

        &-close {
          width: 20px;
          height: 20px;
          float: right;
          cursor: pointer;
        }
      }

      &-body {
        padding: 12px;
        //  padding-top: 2px;
      }

      &-row {
        width: 100%;
        height: 28px;
        border-bottom: 1px solid #2F6460;
      }

      &-row-flex {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #2F6460;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      &-row-no-border {
        border: 0px !important;
      }

      &-row-giftsHistory {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        height: 70px;
        justify-content: center;
        align-items: center;
      }

      &-gifts-img {
        // flex: 0 0 auto;
        // padding-right:30px;
      }

      &-level {
        display: inline-block;
        background-color: #c44c4c;
        border-radius: 50%;
        width: 19px;
        height: 19px;
        border: 1px solid #fff;
        color: #fff;
        font-size: var(--defaultFontSize);
        text-align: center;
        margin-left: 2px;
      }

      &-text {
        display: inline-block;
        font-size: var(--defaultFontSize);
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-top: 1px;
        margin-left: 5px;
      }

      &-text-flex {
        display: inline-block;
        font-size: var(--defaultFontSize);
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-top: 1px;
        margin-left: 5px;
        width: 70%;
        text-align: left;
      }

      &-text-gifts-flex {
        display: inline-block;
        font-size: var(--defaultFontSize);
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-top: 1px;
        margin-left: 5px;
        width: 100%;
        text-align: left;
      }

      &-points-flex {
        display: inline-block;
        font-size: var(--defaultFontSize);
        font-weight: 600;
        color: #FED75F;
        text-align: center;
        margin-top: 1px;
        margin-left: 5px;
        width: 30%;
        text-align: right;
      }

      &-img {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        display: inline-block;

        &-gifts {
          height: 20px;
          width: 16px;
        }
      }


      &-button {
        width: 120px;
        padding-left: 2px;
        padding-right: 2px;
        margin: auto;
        background-color: #2c5955;
        border-radius: 15px;
        height: 30px;
        font-size: var(--mSmallFontSize);
        line-height: 11px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        box-shadow: 5px 5px 0px 0px #380000;

        &:hover {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          border-color: #fff;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }

    & .player-info-footer {
      display: flex;
      justify-content: center;
      align-items: center;

      & .notification-footer-button {
        margin-left: unset;
        margin: unset;
      }
    }
  }



  .four-player-table {
    //  .10Cards {
    //    left: 452px;
    //  }

    .cards {
      top: 345px;
      //  left: 452px;
      left: 45%;

      &-odd {
        //  left: 433px;
        left: 44%;
      }

      &-even {
        //  left: 452px;
        left: 45%;
      }

      .blocked {
        margin-top: 0px;

        &-overlay {
          position: relative;
          //  width: 100%;
          //  height: 100%;

          transition: .3s ease;
          height: 110px;
          width: 76px;
          margin-top: 0;
          margin-left: 2px;
          margin-right: 5px;
          padding-right: 10px;
          border-radius: 5px;
          cursor: default;
          background: rgba(0, 0, 0, 0.20),
        }
      }

      .hovered {
        .allowed {
          //  top: -34px!important;
          top: -34px;
        }

        .card {
          width: 104px;
          height: 138px;
          background-size: 104px 138px;
          margin-top: -24px;
        }

        .blocked-overlay {
          //  height: 134px!important;
          //  width: 100px!important;
          height: 134px;
          width: 100px;
        }
      }

      .hoveredNeighbour {
        .allowed {
          //  top: -24px!important;
          top: -24px;
        }

        .card {
          //  width: 94px!important;
          //  height: 128px!important;
          //  background-size: 94px 128px!important;
          width: 94px;
          height: 128px;
          background-size: 94px 128px;
          margin-top: -12px;
        }

        .blocked-overlay {
          //  height: 124px!important;
          //  width: 90px!important;
          height: 124px;
          width: 90px;
        }
      }

      & .card {
        &-0 {
          left: -192px;
          top: 53px;
          transform: rotate(-19deg);
        }

        &-1 {
          left: -151px;
          top: 38px;
          transform: rotate(-14.41deg);
        }

        &-2 {
          left: -107px;
          top: 25px;
          transform: rotate(-10.56deg);
        }

        &-3 {
          left: -64px;
          top: 15px;
          transform: rotate(-7.43deg);
        }

        &-4 {
          left: -22px;
          top: 9px;
          transform: rotate(-3.89deg);
        }

        &-5 {
          left: 25px;
          top: 5px;
          transform: rotate(-0deg);
        }

        &-6 {
          left: 70px;
          top: 5px;
          transform: rotate(3.89deg);
        }

        &-7 {
          left: 113px;
          top: 8px;
          transform: rotate(7.23deg);
        }

        &-8 {
          left: 155px;
          top: 14px;
          transform: rotate(10.46deg);
        }

        &-9 {
          left: 194px;
          top: 22px;
          transform: rotate(14deg);
        }
      }
    }



    .cards .card {
      width: 80px;
      height: 114px;
      background-size: 80px 114px;
      transition: .3s ease;
      /*  background-color: transparent;
      float: left;
      margin-right: -58px;
      border: none;
      transition: 0.3s ease;
      cursor: pointer;
      position: absolute;
      transform-origin: top left;
      z-index: 40;

      background-position: top center;
      background-repeat: no-repeat;  */
    }

    .played-cards .card {
      width: 75px;
      height: 110px;
      background-size: 75px 110px;
    }

    .played-cards .card-0 {
      top: 10px;
      left: -20px;
    }

    .played-cards .card-1 {
      top: 35px;
      left: 45px;
    }

    .played-cards .card-2 {
      top: 10px;
      left: 100px;
    }

    .played-cards .card-3 {
      top: -10px;
      left: 30px;
    }
    //   .player-top {
    //  top: 5px;
    //  left: 420px;
    //  }

    .played-cards {
      position: absolute;
      top: 190px;
      left: 405px;
    }

    .player-image-timer-wrapper {
      display: inline-block;
      position: relative;
      width: 104px;
      height: 104px;
      text-align: center;
    }

    .player-avatar-wrapper {
      width: 105px;
      height: 105px;
      border-radius: 50%;
      display: inline-block;
      background-color: transparent;
      overflow: hidden;
      padding: 4px;
    }

    .player-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: transparent;
      overflow: hidden;
      border: 5px solid transparent;
    }

    .player-avatar img {
      width: 95px;
      height: 95px;
    }

    .player-firstperson {
      top: 477px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .play-automate-content {
    font-size: var(--defaultFontSize);
  }

  .root-class-design20-light {
    .rooms-table-row-password-text {
      color: #FF8A1A !important;

      &-joined {
        color: black !important;
      }
    }
  }
  // .root-class-design20-normal,
  .root-class-design20-light {
    .app-wrapper {
      .score-table-header,
      .score-table-pules-header {
        .plues-section .under-pules {
          border-bottom: 2px solid #FF8A1A !important; //#FF8A1A;
        }
      }
    }
  }

  .root-class-design20-normal,
  .root-class-design20-dark {
    .app-wrapper {
      .score-table-header,
      .score-table-pules-header {
        .plues-section .under-pules {
          border-bottom: 2px solid #FFC900 !important; //#FF8A1A;
          box-shadow: 1px 1px 2px black;
        }
      }
    }
  }

  .player-balance-popover-body-header {
    font-size: var(--headerFontSize);
  }


  .app-wrapper-inbox:not(.app-wrapper-fullscreen) {

    .quit-round {
      left: 304px !important;
    }

    .last-round {
      right: 304px !important;
    }
  }


  .snack-message-old {
    &-textbox {
      position: fixed;
      min-width: 355px;
      height: auto;
      bottom: 77px;
      right: 40px;
      background: rgba(44, 89, 86, 1);
      border: 1px solid white;
      border-radius: 19px;
      z-index: 10;
      text-align: right;
      padding: 10px;
      color: white;
  
      &-button {
          // width: 120px;
          padding-left: 10px !important;
          padding-right: 10px !important;
          padding-top: 3px !important;
          padding-bottom: 3px !important;
          margin: 2px;
      
          background-color: #2c5955;
          border-radius: 15px;
          // height: 46px !important;
          font-size: var(--largeFontSize);
          // font-size: 15px;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
          border: 1px solid #fff;
          -webkit-box-shadow: 5px 5px 0px 0px #380000;
          -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
          box-shadow: 5px 5px 0px 0px #380000;
  
          &-snack {
            background-color: rgb(243, 144, 1);
            color: #fff;
  
            &:hover {
              text-decoration: none;
              color: #fff;
            }
          }
  
          &-close {
            background-color: white;
            color: #000;
  
            &:hover {
              text-decoration: none;
              color: #000;
            }
          }
  
          &-coins-image {
            width: 14px;
            height: 14px;
            display: inline-block;
            margin-right: 2px;
          }
          &-amount {
            font-weight: 700;
            margin-right: 10px;
          }
      }
    }
  }